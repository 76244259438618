import React, { useEffect, useState, useRef } from 'react';

import {
  Grid,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  Button,
} from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import MaskedInput from 'antd-mask-input';
import { ArrowDropDown, SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import ButtonComponent from '@components/Shared/Buttons/Button';
import { IPerfil } from '@interfaces/UserComplete';
import SearchCEPCorreios from '@utils/SearchCEPCorreios';
import useDebounce from '@hooks/useDebounce';

import Service, {
  IOrigemEspecifica,
  IMotivo,
  IFormaContribuicao,
  ITipoCorrespondecia,
  IPerfil2,
  IRequestCreatePerfil,
  IConvenio,
  IOperacao,
  IOrigemMacro
} from './services';
import { SubTitleBold } from '@src/@styles/styles.global';

import InputEstadoCidade from '@components/Shared/EstadoCidadeInputs';
import InvisibleContent from '@components/Shared/InvisibleContent';
import { isReal, ClearString } from '@utils/Masks';
import { validateYupSchema } from 'formik';
import { number } from 'yup';
import { useAuth } from '@hooks/AuthenticateContext';


interface Iprops {
  data: IPerfil[];
  initPerfil?: number;
  idUsuario: number;
  finishCreate?: Function;
}

interface IRecorrencia {
  id: number;
  descricao: string;
}

interface IRecorrenciaPixCredito {
  intervalo_mes: number;
  descricao: string;
}

const PerfilComponent: React.FC<Iprops> = ({
  data,
  initPerfil,
  /*tiposPerfil,*/ idUsuario,
  finishCreate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { user } = useAuth();

  const [origensEspecificas, setOrigensEspecificas] = useState<
    IOrigemEspecifica[]
  >([]);
  const [origensEspecificasByOrigemMacro, setOrigensEspecificasByOrigemMacro] = useState<IOrigemEspecifica[]>([]);

  const [origensMacro, setOrigensMacro] = useState<
    IOrigemMacro[]
  >([]);
  const [motivosCadastro, setMotivosCadastro] = useState<IMotivo[]>([]);
  const [motivosByOrigemEspecifica, setMotivosByOrigemEspecifica] = useState<IMotivo[]>([]);

  const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [tiposCorrespondecia, setTiposCorrespondecia] = useState<
    ITipoCorrespondecia[]
  >([]);

  const [tiposPerfil2, setTiposPerfil2] = useState<IPerfil2[]>([]);
  const [perfilSelected2, setPerfilSelected2] = useState<IPerfil2>(
    {} as IPerfil2,
  );

  const [valorModifyValorDebito, setValorModifyValorDebito] =
    useState<string>('');
    const [valorModifyValorPadrao, setValorModifyValorPadrao] =
    useState<string>('');
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingCEP, setLoadingCEP] = useState(false);

  const [checkTipoFormContribuicao, setCheckTipoFormContribuicao] =
    useState('');

  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [operacoes, setOperacoes] = useState<IOperacao[]>([]);
  const [operacoesValida, setoperacoesValida] = useState(false);

  const [cidadeLabel, setCidadeLabel] = useState('');
  const [cidadeId, setCidadeID] = useState(0);

  const [recorrencias, setRecorrencias] = useState<IRecorrencia[]>([
    { id: 1, descricao: 'Mensal' },
    { id: 2, descricao: 'Bimestral' },
    { id: 3, descricao: 'Trimestral' },
    { id: 6, descricao: 'Semestral' },
    { id: 12, descricao: 'Anual' },
  ]);

  const [recorrenciasPixCredito, setRecorrenciasPixCredito] = useState<IRecorrenciaPixCredito[]>([
    { intervalo_mes: 999, descricao: 'Única' },
    { intervalo_mes: 1, descricao: 'Mensal' },
    { intervalo_mes: 2, descricao: 'Bimestral' },
    { intervalo_mes: 6, descricao: 'Semestral' },
    { intervalo_mes: 12, descricao: 'Anual' },
  ]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [uf, setUF] = useState<any>();
  //const [cidade, setCidade] = useState<any>();

  const [perfilSelected, setPerfilSelected] = useState<number>(0);
  const [tiposPerfil, setTiposPerfil] = useState<string[]>([]);

  const [convenioSelecionada, setConvenioSelecionada] =
  useState('');

  const [isValid, setIsValid] = useState(false);
  const [message, setMessage] = useState('');

  const [loadingEspecificaByMacro, setLoadingEspecificaByMacro] = useState(false);
  const [loadingMotivoByEspecifica, setLoadingMotivoByEspecifica] = useState(false);

  const emailRegex = /\S+@\S+\.\S+/;

  const validateEmail = (event: any) => {
    const email = event.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setMessage('');
    } else {
      setIsValid(false);
      setMessage('Por favor digite um e-mail válido!');
    }
  };

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };
  const createArrayRange = (
    start: number,
    increment: number,
    length: number,
  ) => {
    let array = [];

    for (let i = start; i <= length; i += increment) {
      array.push(i);
    }

    return array;
  };

  const searchCEPDebounce = async (value: string) => {
    const searchCEPCorreios = new SearchCEPCorreios();
    setLoadingCEP(true);
    const { error, response } = await searchCEPCorreios.SearchCEP(value);
    setLoadingCEP(false);
    if (!error && response) {
      setUF(response.uf);
      let endereco1 = response.logradouro;
      endereco1 = endereco1.toUpperCase();

      let bairro1 = response.bairro;
      bairro1 = bairro1.toUpperCase();
      formRef.setFieldsValue({
        endereco: endereco1,
        bairro: bairro1,
        estado_descricao: response.uf,
        //cidade_id: response.localidade,
      });
    }
  };
  const debounceCEP = useDebounce(searchCEPDebounce, 800);

  const handleMenuItemClick = (_: any, option: any) => {
    setPerfilSelected2(option);

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const dataValuesForm = (index: number) => {
    if (index >= 0 && data[index]) {
      setCheckTipoFormContribuicao(data[index].forma_contribuicao_descricao);
      setCidadeLabel(data[index].contato_offline.cidade_descricao);
      setCidadeID(parseInt(data[index].contato_offline.cidade_id));
      return {

        nome_fantasia: data[index].nome_fantasia || '',
        celular: data[index].celular.contato_online_descricao || '',
        whatsapp: data[index].whatsapp.contato_online_descricao || '',
        telefone_fixo: data[index].telefone_fixo.contato_online_descricao || '',
        telefone_comercial:
          data[index].telefone_comercial.contato_online_descricao || '',
        //email: data[index].email.contato_online_descricao || '',
        endereco: data[index].contato_offline.endereco || '',
        complemento: data[index].contato_offline.complemento || '',
        Distrito: data[index].contato_offline.Distrito || '',
        bairro: data[index].contato_offline.bairro || '',
        cep: data[index].contato_offline.cep || '',
        cidade_id: data[index].contato_offline.cidade_id || '',
        estado_descricao: data[index].contato_offline.estado_descricao || '',
      };
    }
  };

  useEffect(() => {
    const init = async () => {
      const service = new Service();
      const origemEspecificaResponse = await service.loadOrigemEspecifica();
      const origemMacroResponse = await service.loadOrigemMacro();
      const motivosResponse = await service.loadMotivos();
      const tiposPerfilResponse = await service.GetTiposPerfil();

      if (!tiposPerfilResponse.error && tiposPerfilResponse.response) {
        setTiposPerfil2(tiposPerfilResponse.response);
        setPerfilSelected2(tiposPerfilResponse.response[0]);
      }

      if (!motivosResponse.error) {
        setMotivosCadastro(motivosResponse.response);
      }

      if (!origemEspecificaResponse.error) {
        setOrigensEspecificas(origemEspecificaResponse.response);
      }

      if (!origemMacroResponse.error) {
        setOrigensMacro(origemMacroResponse.response);
      }

      const formaContribuicaoResponse = await service.loadFormaContribuicao();

      if (!formaContribuicaoResponse.error) {
        setFormasContribuicao(formaContribuicaoResponse.response);
        let id: any;

        const forma = formaContribuicaoResponse.response.filter(
          x => x.id === id,
        );

        if (forma.length > 0) {
          setCheckTipoFormContribuicao(forma[0].descricao);
        }
      }

      const tiposCorrespondeicaResponse =
        await service.loadTiposCorrespondecia();

      if (!tiposCorrespondeicaResponse.error) {
        setTiposCorrespondecia(tiposCorrespondeicaResponse.response);
      }

      const indexPerfil = filterPerfilByID();
      if (data[indexPerfil]) {
        const conveniosResponse = await service.GetConvenios(
          data[indexPerfil].forma_contribuicao_id,
        );

        if (!conveniosResponse.error && conveniosResponse.response) {
          setConvenios(conveniosResponse.response);
        }
      }
    };


    init();
    if (initPerfil && initPerfil < 0) {
    } else {
      formRef.setFieldsValue(dataValuesForm(0));
    }
    setLoading(false);
  }, []);

  async function onSubmit(values: any) {
    setLoadingCreate(true);

    function limparValor(valor: string): string {
      return valor.replace(/[^\d]+/g, '');
    }

    const valorNumerico = parseFloat(limparValor(valorModifyValorPadrao)) / 100;
    const cepLimpo = limparValor(values.cep);

    const createPerfil: IRequestCreatePerfil = {
      nome: perfilSelected2?.descricao,
      pessoa_id: idUsuario,
      motivo_cadastro_id: values.motivo,
      nome_fantasia: values.nome_fantasia,
      origem_especifica_id: values.origem_id,
      origem_macro_id: values.origem_macro_id,
      tipo_correspondencia_id: values.tipo_correspondencia_id,
      forma_contribuicao_id: values.forma_contribuicao_id,
      tipo_perfil_id: perfilSelected2?.id,
      status_id: 1,
      status_ativo: 1,
      valor_padrao_doacao:  ClearString(valorModifyValorPadrao) / 100,
      contato_offline: {
        bairro: values.bairro,
        cidade_id: values.cidade_id,
        cep: cepLimpo,
        logradouro: '',
        endereco: values.endereco,
        complemento: values.complemento,
        Distrito: values.Distrito,
        status_ativo: 1,
      },
      telefone_fixo: {
        contato_online_descricao: values.telefone_fixo,
        contato_online_contato_primario: 0,
        status_ativo: 1,
      },
      telefone_comercial: {
        contato_online_descricao: values.telefone_comercial,
        contato_online_contato_primario: 0,
        status_ativo: 1,
      },
      celular: {
        contato_online_descricao: values.celular,
        contato_online_contato_primario: 0,
        status_ativo: 1,
      },
      whatsapp: {
        contato_online_descricao: values.whatsapp,
        contato_online_contato_primario: 0,
        status_ativo: 1,
      },
      email: {
        contato_online_descricao: values.email,
        contato_online_contato_primario: 0,
        status_ativo: 1,
      },
    };

    if (checkTipoFormContribuicao === 'DÉBITO AUTOMÁTICO') {
      createPerfil.debito_automatico = {
        id_convenio: values.convenio_id,
        agencia_numero: values.agencia_numero,
        agencia_digito: values.agencia_digito,
        conta_numero: values.conta_numero,
        conta_digito: values.conta_digito,
        operacao: values.operacao,
        valor: ClearString(valorModifyValorDebito) / 100,
        dia_vencimento: values.dia_vencimento,
        status_ativo: 1,
        id_perfil: 0,
        recorrente: 1,
      };
    }

    if (checkTipoFormContribuicao === 'CONTA DE ENERGIA') {
      createPerfil.debito_conta_energia = {
        id_convenio: 0,
        //values.convenio_energia_id,
        conta_contrato_numero: values.conta_contrato_numero,
        recorrencia: values.recorrencia,
        valor: ClearString(valorModifyValorDebito) / 100,
        status_ativo: true,
        id_perfil: 0,
      };
    }

    if (checkTipoFormContribuicao === 'PIX' || 'BOLETO') {
        //values.convenio_energia_id,
        //intervalo_mes: values.intervalo_mes,
        //status_ativo: true,

    }

    const service = new Service();
    const { error, response } = await service.CreatePerfil(createPerfil);

    setLoadingCreate(false);

    if (!error) {
      enqueueSnackbar(`Cadastro finalizado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);
      if (finishCreate) {
        finishCreate();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingUpdate(false);
    }
  }

  const filterCheckTipoFormContribuicao = (id: any) => {
    const forma = formasContribuicao.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoFormContribuicao(forma[0].descricao);
  };

  const loadConvenios = async (id: number) => {
    const servicePerfilCreate = new Service();

    const conveniosResponse = await servicePerfilCreate.GetConvenios(id);

    if (!conveniosResponse.error && conveniosResponse.response) {
      setConvenios(conveniosResponse.response);
    }
  };

  const filterCheckTipoConvenio = (id: any) => {
    const forma = convenios.filter(x => x.id === id);

    if (forma.length > 0) setConvenioSelecionada(forma[0].descricao);
  };

  const loadOperacoes = async (id: number) => {
    let banco = 0;

    convenios.map(item => {
      if (item.id === id) {
        banco = Number(item.id_banco);
      }
    });

    const servicePerfilCreate = new Service();

    const operacoesResponse = await servicePerfilCreate.GetOperacoes(banco);

    if (!operacoesResponse.error && operacoesResponse.response) {
      setOperacoes(operacoesResponse.response);
      validaOperacao(operacoesResponse.response.length);
    }
  };

  const loadOrigemEspecificaByOrigemMacro = async (
    origem_macro_id: string | number,
  ) => {
    const service = new Service();
    const { error, response } = await service.GetTipoOrigemEspecificaByOrigemMacro(
      origem_macro_id,
    );
    if (!error && response) {
      setOrigensEspecificasByOrigemMacro(response?.result || []);
      setLoadingEspecificaByMacro(true);

    } else {
      return 0;
    }
  };

  const loadMotivoByOrigemEspecifica = async (

    origem_especifica_id: string | number,
  ) => {
    const service = new Service();
    const { error, response } = await service.GetTipoMotivoByOrigemEspecifica(
      origem_especifica_id,
    );
    if (!error && response) {
      setMotivosByOrigemEspecifica(response?.result || []);
      setLoadingMotivoByEspecifica (true);

    } else {
      return 0;
    }
  };

  //const loadRecorrencias = async (id: number) => {
  // recorrencias.map(item => {
  //  if (item.id === 1) {
  //    item.descricao = 'TESTE';
  //  }
  //});
  // };

  const validaOperacao = (value: number) => {
    console.log(value);
    if (value === 0) {
      setoperacoesValida(false);
    } else if (value > 0) {
      setoperacoesValida(true);
    }
  };

  const filterPerfilByID = () => {
    if (initPerfil) {
      console.log(data);
      const filter = data.filter(data => data.perfil_id === initPerfil);
      if (filter.length > 0) {
        const indexPerfil = tiposPerfil.findIndex(
          x => x === filter[0].tipo_perfil_descricao,
        );

        setSelectedIndex(indexPerfil);
        setPerfilSelected(indexPerfil);

        setUF(data[indexPerfil].contato_offline.estado_descricao);
        //setCidade(data[indexPerfil].contato_offline.cidade_descricao);
        formRef.setFieldsValue(dataValuesForm(indexPerfil));
        return indexPerfil;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };


  return (
    <Grid container>
      <SubTitleBold> Continuar cadastro de perfil </SubTitleBold>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loading || loadingCreate}
          tip={loadingCreate ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Grid item sm={12}>
              <p style={{ textAlign: 'center', padding: 5 }}>Tipo de Perfil</p>
            </Grid>

            <Grid
              container
              item
              xs={12}
              md={8}
              justify="center"
              alignItems="center"
            >
              <ButtonGroup
                fullWidth
                variant="contained"
                color="secondary"
                ref={anchorRef}
                style={{ height: 40 }}
              >
                <Button onClick={handleToggle}>
                  {perfilSelected2?.descricao}
                </Button>

                <Button
                  color="secondary"
                  onClick={handleToggle}
                  style={{ width: 40 }}
                >
                  <ArrowDropDown />
                </Button>
              </ButtonGroup>
            </Grid>

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
              style={{ position: 'absolute', zIndex: 1000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'left-start' : 'left-start',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        id="split-button-menu"
                        style={{
                          width: 310,
                          maxHeight: 300,
                          overflowY: 'scroll',
                          overflowX: 'hidden',
                        }}
                      >
                        {tiposPerfil2.map((option, index) => (
                          <MenuItem
                            key={index}
                            onClick={event =>
                              handleMenuItemClick(event, option)
                            }
                          >
                            {option?.descricao}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
          <Form
            form={formRef}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={onSubmit}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            requiredMark={false}
            initialValues={{
              nome_fantasia: '',
              origem_id: '',
              celular: '',
              motivo: '',
              telefone_comercial: '',
              telefone_fixo: '',
              valor: '',
              valor_padrao_doacao: '',
              whatsapp: '',
              forma_contribuicao_id: '',
              email: '',
              cep: '',
              estado_descricao: '',
              endereco: '',
              complemento: '',
              Distrito: '',
              bairro: '',
            }}
          >
            <Grid
              container
              style={{
                maxHeight: 350,
                overflowY: 'scroll',
                overflowX: 'hidden',
                paddingRight: 10,
                paddingLeft: 10,
                marginTop: 10,
                marginBottom: 20,
              }}
              justify="center"
              alignItems="center"
            >
              <Grid container justify="center" alignItems="center">
                {/* ORIGEM MACRO, ESPECIFICA e MOTIVO OSID*/}

                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <Form.Item
                        name="origem_macro_id"
                        label="Origem macro"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Selecione uma Origem macro',
                          },
                        ]}
                      >
                        <SelectAntd onChange={(e: string) =>{loadOrigemEspecificaByOrigemMacro(e)}} >
                          {origensMacro.map(item => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item sm={12} xs={12}>
                      <Form.Item
                        name="origem_id"
                        label="Origem específica"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Selecione uma Origem específica',
                          },
                        ]}
                      >
                        <SelectAntd disabled={!loadingEspecificaByMacro} placeholder="Origem específica" onChange={(e: string) =>{loadMotivoByOrigemEspecifica(e)}}>
                          {origensEspecificasByOrigemMacro.map(item => (
                            <SelectAntd.Option key={item.id} value={item.id}>
                              {item.descricao}
                            </SelectAntd.Option>
                          ))}
                        </SelectAntd>
                      </Form.Item>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <Form.Item
                      name="motivo"
                      label="Motivo"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um motivo',
                        },
                      ]}
                    >
                      <SelectAntd disabled={!loadingMotivoByEspecifica} placeholder="Motivo">
                        {motivosByOrigemEspecifica.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                {/* { formaContribuicao, Dados convenio,  Valor e tipoCorrespondencia } */}
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Form.Item
                      name="forma_contribuicao_id"
                      label="Forma de contrib."
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione uma forma de Contribuição',
                        },
                      ]}
                    >
                      <SelectAntd
                        placeholder="Forma de contrib."
                        onChange={e => {
                          loadConvenios(Number(e));
                          filterCheckTipoFormContribuicao(e);
                        }}
                      >
                        {formasContribuicao.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <Form.Item
                      name="tipo_correspondencia_id"
                      label="Tipo de corresp."
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um tipo de correspondencia',
                        },
                      ]}
                    >
                      <SelectAntd placeholder="Tipo de corresp.">
                        {tiposCorrespondecia.map(item => (
                          <SelectAntd.Option key={item.id} value={item.id}>
                            {item.descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>
                  <InvisibleContent
                    visible={checkTipoFormContribuicao === 'DÉBITO AUTOMÁTICO'}
                  >
                    <>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="convenio_id"
                          label="Convênio"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um convênio',
                            },
                          ]}
                        >
                          <SelectAntd
                            placeholder="Convênio"
                            onChange={e => {
                              loadOperacoes(Number(e));
                            }}
                            onSelect={(e: any) => filterCheckTipoConvenio(e)}
                          >
                            {convenios.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.descricao + ' - ' + item.numero_convenio}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="dia_vencimento"
                          label="Dia do vencimento"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Selecione um dia',
                            },
                          ]}
                        >
                          <SelectAntd placeholder="Dia do vencimento">
                            {createArrayRange(1, 1, 31).map(i => (
                              <SelectAntd.Option key={i} value={i}>
                                {i}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={9} xs={12}>
                        <Form.Item
                          name="agencia_numero"
                          label="Número da Agência"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                            { max: 4, message: 'Máximo de  4 caracter' },
                            {
                              pattern: /^[a-zA-Z0-9]+$/,
                              message: "Somente números ou letras"
                            }
                          ]}
                        >
                          <Input placeholder="Número da Agência" />
                        </Form.Item>
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="agencia_digito"
                          label="Digito da Agência"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                            { max: 1, message: 'Máximo de  1 caracter' },
                            {
                              pattern: /^[a-zA-Z0-9]+$/,
                              message: "Somente números ou letras"
                            }
                          ]}
                        >
                          <Input placeholder="Digito da Agência" />
                        </Form.Item>
                      </Grid>

                      <InvisibleContent
                        visible={convenioSelecionada
                        .toLocaleUpperCase()
                        .includes('ITAÚ') }
                      >

                      <Grid item sm={9} xs={12}>
                        <Form.Item
                          name="conta_numero"
                          label="Número da conta"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                               max: 5, message: 'Máximo de 5 caracteres',
                            },
                          ]}
                        >
                          <Input placeholder="Número da conta" />
                        </Form.Item>
                      </Grid>

                      </InvisibleContent>

                      <InvisibleContent
                        visible={!convenioSelecionada
                        .toLocaleUpperCase()
                        .includes('ITAÚ') }
                      >

                      <Grid item sm={9} xs={12}>
                        <Form.Item
                          name="conta_numero"
                          label="Número da conta"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Número da conta" />
                        </Form.Item>
                      </Grid>

                      </InvisibleContent>



                      <Grid item sm={3} xs={12}>
                        <Form.Item
                          name="conta_digito"
                          label="Digito da conta"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input placeholder="Digito da conta" />
                        </Form.Item>
                      </Grid>

                      {/*<Grid item sm={6} xs={12}>
                        <Form.Item
                          name="operacao"
                          label="Operação"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Input placeholder="Operação" />
                        </Form.Item>
                      </Grid>*/}

                      <InvisibleContent
                        visible={convenioSelecionada
                        .toLocaleUpperCase()
                        .includes('SANTANDER') }
                      >

                        <Grid item sm={6} xs={12}>
                          <Form.Item
                            name="operacao"
                            label="Operação"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                          < SelectAntd
                              placeholder="Operação"
                              disabled={!operacoesValida}
                            >
                              {operacoes.map(item => (
                                <SelectAntd.Option
                                  key={item.codigo_operacao}
                                  value={item.codigo_operacao}
                                >
                                  {item.codigo_operacao + ' - ' + item.descricao}
                                </SelectAntd.Option>
                              ))}
                            </SelectAntd>
                          </Form.Item>
                        </Grid>
                      </InvisibleContent>

                      <InvisibleContent
                        visible={convenioSelecionada
                        .includes('CAIXA') }
                      >

                        <Grid item sm={6} xs={12}>
                          <Form.Item
                            name="operacao"
                            label="Operação"
                            hasFeedback
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                          < SelectAntd
                              placeholder="Operação"
                              disabled={!operacoesValida}
                            >
                              {operacoes.map(item => (
                                <SelectAntd.Option
                                  key={item.codigo_operacao}
                                  value={item.codigo_operacao}
                                >
                                  {item.codigo_operacao + ' - ' + item.descricao}
                                </SelectAntd.Option>
                              ))}
                            </SelectAntd>
                          </Form.Item>
                        </Grid>
                      </InvisibleContent>



                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito"
                          label="Valor do débito"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Valor do débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito: isReal(e.target.value),
                              });
                              setValorModifyValorDebito(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>
                    </>
                  </InvisibleContent>

                  {/* zkhkxvhnkcvklxcjvlkxjvlxjvlxjvcl\çkçxkxçlvjcxçlvmlcxvjclkvjxçkvçxlvkxçcvçxlcvkçxckvçxckvzçxlkvçlxckvçxckvçxk */}
                  <InvisibleContent
                    visible={checkTipoFormContribuicao === 'CONTA DE ENERGIA'}
                  >
                    <>
                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="convenio_energia_id"
                          label="Convênio"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                              message: 'Selecione um convênio',
                            },
                          ]}
                        >
                          <Input placeholder="Convênio" disabled />
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="conta_contrato_numero"
                          label="Número da conta"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input placeholder="Número da conta" />
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="recorrencia"
                          label="Recorrência"
                          hasFeedback
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <SelectAntd placeholder="Recorrência">
                            {recorrencias.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.id + ' - ' + item.descricao}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Form.Item>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Form.Item
                          name="valor_debito_energia"
                          label="Valor do débito"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Valor do débito"
                            onChange={(e: any) => {
                              formRef.setFieldsValue({
                                valor_debito_energia: isReal(e.target.value),
                              });
                              setValorModifyValorDebito(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Grid>
                    </>
                  </InvisibleContent>
                </Grid>

                <InvisibleContent
                    visible={checkTipoFormContribuicao != 'DÉBITO AUTOMÁTICO'}
                  >
                    <>
                <Grid item lg={12} xs={12}>
                <Form.Item label="Valor padrão doação" hasFeedback name="valor_padrao_doacao">
                    <Input
                      //disabled
                      onChange={(e: any) => {
                      formRef.setFieldsValue({
                        valor_padrao_doacao: isReal(e.target.value),
                      });
                      setValorModifyValorPadrao(e.target.value);
                      }}
                      placeholder="Valor" />
                  </Form.Item>
                </Grid>
                </>
                </InvisibleContent>
                {/* { formaContribuicao, Valor e tipoCorrespondencia } */}

                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}
                <Grid container>
                  <Grid item lg={6} xs={12}>
                    <Form.Item label="Celular" hasFeedback name="celular"
                      rules={[
                        {
                          pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                          message: 'Número de celular inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 11111-1111"
                        placeholder="Celular"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item label="WhatsApp" hasFeedback name="whatsapp"
                      rules={[
                        {
                          pattern: /\(\d{2,}\) \d{5,}\-\d{4}/,
                          message: 'Número de celular inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 11111-1111"
                        placeholder="WhatsApp"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone fixo"
                      hasFeedback
                      name="telefone_fixo"
                      rules={[
                        {
                          pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          message: 'Número de telefone inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 1111-1111"
                        placeholder="Telefone fixo"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Form.Item
                      label="Telefone comercial"
                      hasFeedback
                      name="telefone_comercial"
                      rules={[
                        {
                          pattern: /\(\d{2,}\) \d{4,}\-\d{4}/,
                          message: 'Número de telefone inválido'
                        }

                      ]}
                    >
                      <MaskedInput
                        mask="(11) 1111-1111"
                        placeholder="Telefone comercial"
                      />
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="E-mail"
                    hasFeedback
                    name="email"
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+\.([a-z])+/,
                        message: 'Somente letras (a - z), números (0 - 9) e pontos (.) são permitidos.'
                      }

                    ]}
                  >
                    <Input
                      placeholder="E-mail"
                      //onChange={(e: any) => {
                        //validateEmail(e);
                      //}}
                    />
                  </Form.Item>
                 {/*{message}*/}
                </Grid>
                {/* { celular, whatsapp, tel fixo, ttel comercial, email } */}

                <Spin spinning={loadingCEP} tip="Buscando dados do CEP">
                  <Grid container>
                    {/* { cep, uf, cidade } */}
                    <Grid item lg={12} xs={12}>
                      <Form.Item label="CEP" hasFeedback name="cep">
                        <MaskedInput
                          mask="11111-111"
                          placeholder="CEP"
                          onChange={(e: any) => debounceCEP(e.target.value)}
                        />
                      </Form.Item>
                    </Grid>

                    <InputEstadoCidade
                      ufProps={uf}
                      cidadeID={cidadeId}
                      cidadeLabel={cidadeLabel}
                      formRef={formRef}
                    />
                    {/* { cep, uf, cidade } */}

                    {/* { endereço, numero } */}
                    <Grid item lg={12} xs={12}>
                      <Form.Item
                        label="Endereço"
                        hasFeedback
                        name="endereco"
                        rules={
                          [
                            { required: false },
                            {pattern: /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9\-\ ]+$/,
                            message: 'Somente letras e números'}
                          ]
                        }
                      >
                        <Input placeholder="Endereço" />
                      </Form.Item>
                    </Grid>
                    {/* { endereço, numero } */}

                    {/* { complemento, bairro } */}

                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Bairro"
                        hasFeedback
                        name="bairro"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Bairro" />
                      </Form.Item>
                    </Grid>

                    <Grid item sm={6} xs={12}>
                      <Form.Item
                        label="Distrito"
                        hasFeedback
                        name="Distrito"
                        rules={[{ required: false }]}
                      >
                        <Input placeholder="Distrito" />
                      </Form.Item>
                    </Grid>

                    <Grid container>
                      <Grid item sm={12} xs={12}>
                        <Form.Item
                          label="Complemento"
                          hasFeedback
                          name="complemento"
                          rules={
                            [

                              { max: 45, message: 'Máximo de 45 caracteres' },
                            ]
                          }
                        >
                          <Input onInput={handleInput} placeholder="Complemento" />
                        </Form.Item>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item sm={12} xs={12}>
                        <Form.Item
                          name="nome_fantasia"
                          label="Nome de tratamento"
                        >
                          <Input
                            onInput={handleInput}
                            placeholder="Nome de tratamento"
                            disabled
                          />
                        </Form.Item>
                      </Grid>
                    </Grid>
                    {/* { complemento, bairro } */}
                  </Grid>
                </Spin>
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={loadingUpdate}
                    loading={loadingUpdate}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default PerfilComponent;
