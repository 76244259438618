import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { SubTitleBold } from '@src/@styles/styles.global';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { AddOutlined, Visibility } from '@material-ui/icons';
import InvisibleContent from '@components/Shared/InvisibleContent';

import { IPerfil } from '@interfaces/UserComplete';
import useDebounce from '@hooks/useDebounce';
import Services from './services';

interface IProps {
  pessoa_id: number;
  id_responsavel: number;
  nome_responsavel: string;
  id_indicador: number;
  nome_indicador: string;
  finishUpdate?: Function;
}

const optionsFilter = ['ID', 'CPF'];

const EditCadastro:React.FC<IProps> = ({
  pessoa_id,
  finishUpdate,
  id_responsavel,
  id_indicador,
  nome_indicador,
  nome_responsavel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [statePessoa, setStatePessoa] = useState({
    Nome: '',
    id: '',
  });
  const [viewConfirmation, setViewConfirmation] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );
  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const [perfis, setPerfis] = useState<IPerfil[]>([]);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingUpdatePerfil, setLoadingUpdatePerfil] = useState(false);
  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [enableSearchForm, setEnableSearchForm] = useState(false);

  const handleMenuItemClick = (_: any, option: any) => {
    console.log(option);
    setTipoPerfilSelected(option.key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);
    //setSelectedFilterIndicador(value);
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };

  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

  const { error, response } = await service.getPessoaByDocumento(documento);

  if (!error && response) {
    setPerfis(response.pessoa.perfis);
    if (response.pessoa.perfis.length <= 0) {
      setRespostaErroBuscaPerfil(
        'Não existe perfil cadastrado para este CPF/CNPJ',
      );
    }
    if (!error && response) {
      setStatePessoa({
        Nome: response.pessoa.nome,
        id: response.pessoa.id,
      });
      setPerfis(response.pessoa.perfis);
      setViewConfirmation(true);
    }
  } else {
    setViewConfirmation(false);
    setRespostaErroBuscaPerfil(
      'Não existe uma pessoa cadastrada para este CPF/CNPJ',

    );
  }
  setLoadingTipoPerfil(false);
};

const getPessoaByID = async (id: number) => {
  setRespostaErroBuscaPerfil('');
  setLoadingTipoPerfil(true);
  const service = new Services();

  const { error, response } = await service.getPessoaByID(id);

  if (!error && response) {
    setPerfis(response.pessoa.perfis);
    if (response.pessoa.perfis.length <= 0) {
      setRespostaErroBuscaPerfil('Não existe perfil cadastrado para este ID');
    }
    if (!error && response) {
      setStatePessoa({
        Nome: response.pessoa.nome,
        id: response.pessoa.id,
      });
      setPerfis(response.pessoa.perfis);
      setViewConfirmation(true);
    }
  } else {
    setRespostaErroBuscaPerfil(
      'Não existe uma pessoa cadastrada para este ID',
    );
    setViewConfirmation(false);
  }
  setLoadingTipoPerfil(false);
};

const debounceOnChange = (value: string) => {
  formRef.setFieldsValue({
    tipo_perfil: undefined,
  });
  setPerfis([]);
  if (selectedFilter === 'CPF') {
    debounceGetPessoaByDocumento(value);
  } else if (selectedFilter === 'ID') {
    debounceGetPessoaByID(parseInt(value));
  }
};

const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
const debounceGetPessoaByID = useDebounce(getPessoaByID, 1000);


async function onSubmit(values: any) {
  const componentPerfilServices = new Services();
  setLoadingUpdatePerfil(true);

  //const updateTipoPerfil: any = {
  //  tipo_perfil_id: values.id,
  //};

  const updatePerfilResponse = await componentPerfilServices.UpdateResponsavel({
    pessoa: {
      id: pessoa_id,
      id_pessoa_responsavel: Number(statePessoa.id) ,
      id_pessoa_indicador: id_indicador,
      nome_pessoa_responsavel: statePessoa.Nome,
      nome_pessoa_indicador: nome_indicador,
      //documento_indicador: values.documento_indicador,
    },

  });

  if (!updatePerfilResponse.error) {
    setLoadingUpdate(false);
    setLoading(false);
    enqueueSnackbar(`Responsável alterado com sucesso!`, {
      title: 'Responsável alterado com sucesso!',
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

    if (finishUpdate) {
      setLoadingUpdate(false);
      await finishUpdate();
    }
  } else {
    setLoadingUpdate(false);
    setLoading(false);
    enqueueSnackbar(`Ocorreu um erro`, {
      title: 'Ocorreu um erro ',
      variant: 'error',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
    enqueueSnackbar(`Verifique se o cadastro não é menor de idade`, {
      title: 'Verifique se o cadastro não é menor de idade',
      variant: 'warning',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
  }
}

  return (
    <Grid container>

      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingUpdatePerfil}
          tip={loadingUpdatePerfil ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form
              form={formRef}
              scrollToFirstError={true}
              onFinish={onSubmit}
              requiredMark={false}
              style={{ width: '100%' }}
              layout="vertical"
            >
              <Grid item lg={12} xs={12}>
                <Form.Item>
                  <Input
                    disabled
                    placeholder="CÓDIGO DO RESPONSÁVEL"
                    value={id_responsavel}
                  />
                </Form.Item>
              </Grid>

              <Grid item container justify="space-around" sm={12} xs={12} lg={12}>

                    <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          Filtros
                          <SelectAntd
                            style={{ width: '100%' }}
                            onChange={(e: string) => {
                              setValueInput('');
                              handleSelectFilter(e);
                              setViewConfirmation(false);
                              formRef.setFieldsValue({
                                cpf: undefined,
                              });
                              setEnableSearchForm(true);
                            }}
                          >
                            {optionsFilter.map((option, index) => (
                              <SelectAntd.Option key={option} value={option}>
                                {option}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Grid>

                        <Grid
                          item
                          md={9}
                          lg={9}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          <Form.Item
                            name="cpf"
                            label="Buscar por"
                            rules={[
                              {
                                required: true,
                                message: 'Digite um documento',
                              },
                            ]}
                            style={{ paddingTop: 0 }}
                          >
                            <Input
                              disabled={!enableSearchForm}
                              minLength={14}
                              maxLength={15}
                              name={selectedFilter}
                              value={valueInput}
                              type={
                                selectedFilter === 'ID' || selectedFilter === 'ID AFIPE'
                                  ? 'number'
                                  : 'text'
                              }
                              onChange={(e: any) => {
                                setViewConfirmation(false);
                                if (selectedFilter === 'CPF') {
                                  formRef.setFieldsValue({
                                    cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                                  });
                                }
                                if (
                                  selectedFilter === 'CPF' &&
                                  e.target.value.length >= 14
                                ) {
                                  debounceOnChange(e.target.value);
                                } else if (selectedFilter !== 'CPF') {
                                  debounceOnChange(e.target.value);
                                }
                              }}
                            />
                          </Form.Item>
                        </Grid>

                        {viewConfirmation && (
                          <Grid container>
                            <Grid item lg={2} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item  label="ID" name="documento_indicador">
                              <strong>
                                <Input
                                  placeholder="ID"
                                  value= {statePessoa.id}
                                />
                            </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item lg={8} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item  label="Nome"  name="teste">
                              <strong>
                                <Input
                                  placeholder="Nome"
                                  value= {statePessoa.Nome}
                                />
                            </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item sm={2} xs={12} style={{  marginTop: 25 }}>
                              <Form.Item>
                                <IconButton
                                  onClick={() => window.open(`/cadastro/${statePessoa.id}/`)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Form.Item>
                              </Grid>

                          </Grid>
                        )}
                        <div style={{color: "#FF0000"} }>
                          {respostaErroBuscaPerfil}
                        </div>

                      </Grid>
                    </Spin>
                </Grid>

                <InvisibleContent visible={loadingTipoPerfil === false && respostaErroBuscaPerfil === ''}>
                  <Grid container justify="center" alignItems="center">
                    <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                      <ButtonComponent
                        color="primary"
                        disabled={loadingUpdate}
                        loading={loadingUpdate}
                        fullWidth
                        type="submit"
                      >
                        <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                      </ButtonComponent>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={loadingTipoPerfil !== false || respostaErroBuscaPerfil !== ''}>
                  <Grid container justify="center" alignItems="center">
                    <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                      <ButtonComponent
                        color="primary"
                        disabled
                        loading={loadingUpdate}
                        fullWidth
                        type="submit"
                      >
                        <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                      </ButtonComponent>
                    </Grid>
                  </Grid>
                </InvisibleContent>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default EditCadastro;
