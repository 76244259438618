import React, { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import useDebounce from '@hooks/useDebounce';
import { SubTitleBold, TitlePage } from '@src/@styles/styles.global';
import Services, { IFormaContribuicao, IEstrategias } from './services';
import { IPerfil } from '@interfaces/UserComplete';
import { FormatDate, CurrencyFormat } from '@utils/formats';
import { MaskCPFeCNPJ, isReal, ClearString } from '@utils/Masks';
import Tag from '@src/components/Shared/Tag';
import { format, addDays, subDays } from 'date-fns';
import { useLoading } from '@hooks/LoadingContext';
import {
  ChevronRight,
  Close,
  DoneOutlined,
  SaveOutlined,
} from '@material-ui/icons';

import { useAuth } from '@hooks/AuthenticateContext';

interface IProps {
  nextStep: Function;
}
const DATAMAX = addDays(new Date(), 0).toISOString().split('T')[0];
const DATAMIN = addDays(new Date(), -45507).toISOString().split('T')[0];

const optionsFilter = ['ID', 'CPF/CNPJ'];
const InsercaoDoacoes: React.FC<IProps> = ({ nextStep }) => {
  const { removeLoading, setValues } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [formasContruibuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [estrategias, setEstrategias] = useState<IEstrategias[]>([]);
  const [perfis, setPerfis] = useState<IPerfil[]>([]);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [viewConfirmation, setViewConfirmation] = useState(false);
  const [viewConfirmation2, setViewConfirmation2] = useState(false);
  const [valorModify, setValorModify] = useState(0.0);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );
  const [enableSearchForm, setEnableSearchForm] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [stateConfirme, setStateConfirme] = useState({
    CPF: '',
    tipoPerfil: '',
    Nome: '',
    Valor: '',
    DataDoacao: '',
    observacao: '',
  });
  const [confirme, setConfirme] = useState(false);

  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };
  const loadFormasContribuicao = async () => {
    const service = new Services();
    const { error, response } = await service.loadFormaContribuicao();
    if (!error && response) {
      setFormasContribuicao(response);
    }
    removeLoading();
  };
  const loadEstrategias = async () => {
    setValues(true);
    const service = new Services();
    console.log(selectedFilter);
    const { error, response } = await service.loadEstrategias();
    if (!error && response) {
      setEstrategias(response);
    }
  };
  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();
    const { error, response } = await service.getPessoaByDocumento(documento);
    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil(
          'Não existe uma pessoa cadastrada para este CPF/CNPJ',
        );
      }
      if (!error && response) {
        setStateConfirme({
          Nome: response.pessoa.nome,
          CPF: response.pessoa.documento,
          DataDoacao: '',
          Valor: '',
          tipoPerfil: '',
          observacao: '',
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation2(true);
      }
    }else {
      setViewConfirmation2(false);
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este CPF/CNPJ',
      );
    }
    setLoadingTipoPerfil(false);
    havePerfil();
  };
  const getPessoaByID = async (id: number) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();
    const { error, response } = await service.getPessoaByID(id);
    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil(
          'Não existe perfil cadastrado para este ID',
        );
      }
      if (!error && response) {
        setStateConfirme({
          Nome: response.pessoa.nome,
          CPF:
            response.pessoa.documento !== null
              ? MaskCPFeCNPJ(response.pessoa.documento).valueModify
              : '',
          DataDoacao: '',
          Valor: '',
          tipoPerfil: '',
          observacao: '',
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation2(true);
      }
    } else {
      setViewConfirmation2(false);
      setRespostaErroBuscaPerfil(
        'Não existe perfil cadastrado para este ID',
      );
    }
    setLoadingTipoPerfil(false);
    havePerfil();
  };
  const debounceOnChange = (value: string) => {
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
    if (selectedFilter === 'CPF/CNPJ') {
      debounceGetPessoaByDocumento(value);
    } else if (selectedFilter === 'ID') {
      debounceGetPessoaByID(parseInt(value));
    }
  };
  const havePerfil = () => {
    if (perfis.length >= 0) {
      if (nextStep) {
        nextStep();
      }
    }
  };
  const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
  const debounceGetPessoaByID = useDebounce(getPessoaByID, 600);
  useEffect(() => {
    loadEstrategias();
    loadFormasContribuicao();
    window.document.title = 'CRM - Inserção de Doações';
  }, []);
  const onFinish = async (values: any) => {
    if (!confirme) {
      setStateConfirme({
        DataDoacao: values.data_doacao,
        Valor: values.valor,
        tipoPerfil: perfis.filter(
          perfil => perfil.perfil_id == values.tipo_perfil,
        )[0].tipo_perfil_descricao,
        CPF: stateConfirme.CPF,
        Nome: stateConfirme.Nome,
        observacao: values.observacao,
      });
      setViewConfirmation(true);
      if (nextStep) nextStep();
      return;
    }
    if (nextStep) nextStep();
    setLoadingFinish(true);
    const service = new Services();
    const { error } = await service.inserirDoacao({
      estrategia_id: values.estrategia_id,
      origem_doacao_id: values.origem_doacao_id,
      perfil_id: values.tipo_perfil,
      valor_doacao: (valorModify / 100).toString(),
      data_doacao: values.data_doacao,
      observacao: values.observacao,
    });
    setLoadingFinish(false);
    if (!error) {
      setPerfis([]);
      formRef.setFieldsValue({
        cpf: '',
        tipo_perfil: null,
        valor: '',
      });
      setViewConfirmation(false);
      setConfirme(false);
      enqueueSnackbar(`Inserção feita com sucesso`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <Grid container style={{ padding: 10 }}>
      {/* // HEADER PAGE */}
      <Grid container alignItems="center" justify="flex-start">
        <Grid container item lg={12}>
          <Grid item lg={12} xs={12}>
            <TitlePage style={{ margin: 10, paddingTop: 6 }}>
              Inserção de Doações
            </TitlePage>
          </Grid>
          <Grid item lg={2} xs={12}>
            <Tag color="default"> Doação individual </Tag>
          </Grid>
        </Grid>
      </Grid>
      {/* // HEADER PAGE */}
      <Grid container item lg={5} xs={12} style={{}}>
        <Spin spinning={loadingFinish}>
          <Form
            form={formRef}
            size="middle"
            layout="vertical"
            scrollToFirstError={true}
            onFinish={onFinish}
            labelAlign="left"
            requiredMark={false}
          >
            <Grid container>
              <Grid
                item
                lg={6}
                xs={12}
                id="page-inserir-doacoes-step1"
                style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
              >
                <Form.Item
                  name="data_doacao"
                  label="Data de doação"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione a data de doação',
                    },
                  ]}
                  style={{ paddingTop: 0 }}
                >
                  <Input
                    type="date"
                    onChange={() => {
                      if (nextStep) nextStep();
                    }}
                    max={DATAMAX}
                    min={DATAMIN}
                  />
                </Form.Item>
              </Grid>
              <Grid
                item
                lg={6}
                xs={12}
                id="page-inserir-doacoes-step2"
                style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
              >
                <Form.Item
                  name="origem_doacao_id"
                  label="Origem de doação"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma origem de doação',
                    },
                  ]}
                >
                  <SelectAntd
                    placeholder="Origem de doação"
                    onChange={() => {
                      if (nextStep) nextStep();
                    }}
                  >
                    {formasContruibuicao.map(item => (
                      <SelectAntd.Option key={item.id} value={item.id}>
                        {item.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                id="page-inserir-doacoes-step3"
                style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
              >
                <Form.Item
                  name="estrategia_id"
                  label="Estratégia"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma Estratégia',
                    },
                  ]}
                >
                  <SelectAntd
                    placeholder="Estratégia"
                    onChange={() => {
                      if (nextStep) nextStep();
                    }}
                  >
                    {estrategias.map((item, index) => (
                      <SelectAntd.Option key={index} value={item.id}>
                        {item.nome}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
              <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
                <Grid container>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={12}
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                    id="page-inserir-doacoes-step4"
                  >
                    Filtros
                    <SelectAntd
                      style={{ width: '100%' }}
                      placeholder="Filtros"
                      onChange={(e: string) => {
                        setValueInput('');
                        handleSelectFilter(e);
                        setViewConfirmation(false);
                        setViewConfirmation2(false);
                        formRef.setFieldsValue({
                          cpf: undefined,
                        });
                        if (nextStep) nextStep();
                        setEnableSearchForm(true);
                      }}
                    >
                      {optionsFilter.map((option, index) => (
                        <SelectAntd.Option key={option} value={option}>
                          {option}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Grid>
                  <Grid
                    item
                    md={9}
                    lg={9}
                    xs={12}
                    id="page-inserir-doacoes-step5"
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="cpf"
                      label="Buscar por"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Digite um documento',
                        },
                      ]}
                      style={{ paddingTop: 0 }}
                    >
                      <Input
                        disabled={!enableSearchForm}
                        maxLength={18}
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' ||
                          selectedFilter === 'ID AFIPE'
                            ? 'number'
                            : 'text'
                        }
                        onChange={(e: any) => {
                          setViewConfirmation(false);
                          if (selectedFilter === 'CPF/CNPJ') {
                            formRef.setFieldsValue({
                              cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                            });
                          }
                          if (
                            selectedFilter === 'CPF/CNPJ' &&
                            e.target.value.length >= 11
                          ) {
                            debounceOnChange(e.target.value);
                          } else if (selectedFilter !== 'CPF/CNPJ') {
                            debounceOnChange(e.target.value);
                          }
                        }}
                      />
                    </Form.Item>
                  </Grid>
                  <div style={{color: "#FF0000"} }>
                          {respostaErroBuscaPerfil}
                        </div>
                  <Grid
                    item
                    lg={6}
                    xs={12}
                    id="page-inserir-doacoes-step6"
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="tipo_perfil"
                      label="Tipo de perfil"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um perfil',
                        },
                      ]}
                      style={{ paddingTop: 0 }}
                    >
                      <SelectAntd
                        disabled={perfis.length <= 0}
                        placeholder="Tipo de perfil"
                        onChange={() => {
                          if (nextStep) nextStep();
                        }}
                      >
                        {perfis.map((item, index) => (
                          <SelectAntd.Option key={index} value={item.perfil_id}>
                            {item.tipo_perfil_descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    xs={12}
                    id="page-inserir-doacoes-step7"
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="valor"
                      label="Valor"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Digite um valor',
                        },
                        {
                         // pattern: /^[1-9][0-9]{0,5}$/,
                         // message: 'Digite um valor maior que R$ 0,00',
                        },
                      ]}
                      style={{ paddingTop: 0 }}
                    >
                      <Input
                        onChange={(e: any) => {
                          formRef.setFieldsValue({
                            valor: isReal(e.target.value),
                          });
                          setValorModify(ClearString(e.target.value));
                        }}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid
                    item
                    lg={9}
                    xs={12}
                    id="page-inserir-doacoes-step8"
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="observacao"
                      label="Observação"
                      hasFeedback

                      style={{ paddingTop: 0 }}
                    >
                      <Input
                        onChange={(e: any) => {

                        }}
                      />
                    </Form.Item>
                  </Grid>




                  <Grid
                    container
                    justify="center"
                    item
                    lg={3}
                    style={{ marginTop: 30, paddingLeft: 0, paddingRight: 10 }}
                    id="page-inserir-doacoes-step8"
                  >
                    <ButtonComponent
                      color="secondary"
                      fullWidth
                      type="submit"
                      style={{ height: 34.5, width: '100%' }}
                    >
                      Importar <ChevronRight style={{ marginLeft: 10 }} />
                    </ButtonComponent>
                  </Grid>
                </Grid>
              </Spin>
            </Grid>
          </Form>
        </Spin>
        {viewConfirmation && (
          <Grid
            container
            id="page-inserir-doacoes-step9"
            style={{ padding: 10, paddingBottom: 0 }}
          >
            <Grid item lg={12} xs={12} sm={12}>
              <SubTitleBold>Confirme os dados para concluir</SubTitleBold>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              CPF:{' '}
              <strong>{MaskCPFeCNPJ(stateConfirme.CPF).valueModify}</strong>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              Perfil: <strong>{stateConfirme.tipoPerfil}</strong>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              Nome: <strong>{stateConfirme.Nome}</strong>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              Valor: <strong>R$ {stateConfirme.Valor}</strong>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              Observação: <strong> {stateConfirme.observacao}</strong>
            </Grid>

            {/* <Grid item lg={9}>
                Data da Doação:{' '}
                <strong>
                  {new Date(stateConfirme.DataDoacao).toISOString()}
                </strong>
              </Grid> */}
            <Grid
              item
              lg={4}
              xs={12}
              sm={12}
              style={{ paddingTop: 10, marginRight: 10 }}
            >
              <ButtonComponent
                color="primary"
                onClick={() => {
                  setConfirme(true);
                  formRef.submit();
                  if (nextStep) nextStep();
                }}
                fullWidth
                disabled={confirme}
                loading={confirme}
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Confirmar
              </ButtonComponent>
            </Grid>
            <Grid item lg={4} xs={12} sm={12} style={{ paddingTop: 10 }}>
              <ButtonComponent
                color="default"
                onClick={() => {
                  setConfirme(false);
                  setViewConfirmation(false);
                  if (nextStep) nextStep();
                }}
                fullWidth
              >
                <Close style={{ marginRight: 10 }} /> Cancelar
              </ButtonComponent>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default InsercaoDoacoes;
