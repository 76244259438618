import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';
import Modal from '@components/Shared/Modal/ModalPure';
import { Form, Input, Spin, Select as SelectAntd } from 'antd';
import { Grid } from '@material-ui/core';
import ButtonComponent from '@src/components/Shared/Buttons/Button';

import Service, { IUpdate, IData } from '../../../services';
import { SaveOutlined } from '@material-ui/icons';
import is from 'date-fns/esm/locale/is/index.js';

interface IProps {
  controlModal: Function;
  openModal: boolean;
  afterFinish: Function;
  update: 'macro' | 'especifica' | 'motivo' | 'tipoPerfil';
  title: string;
  objetoUpdate: IUpdate;
}

const OrigemMacro: React.FC<IProps> = ({
  controlModal,
  openModal,
  afterFinish,
  update,
  title,
  objetoUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [origensMacro, setOrigensMacro] = React.useState<IData[]>([]);
  const getOrigemMacro = async () => {
    const service = new Service();
    const responseOrigemMacro = await service.loadOrigemMacro();

    if (!responseOrigemMacro.error && responseOrigemMacro.response) {
      setOrigensMacro(responseOrigemMacro.response);
    }
  };

  useEffect(() => {
    if (update === 'especifica') {
      getOrigemMacro();
    }
  }, [update, objetoUpdate]);

  const onFinish = async (values: any) => {
    var isError = true;
    setLoading(true);
    const service = new Service();

    if (update === 'macro') {
      const { error } = await service.updateOrigemMacro({
        id: objetoUpdate.id,
        descricao: values.descricao,
        status_ativo: 1,
      });
      isError = error;
    } else if (update === 'especifica') {
      const { error } = await service.updateOrigemEspecifica({
        id: objetoUpdate.id,
        origem_macro_id: values.origem_macro_id,
        descricao: values.descricao,
        status_ativo: 1,
      });
      isError = error;
    } else if (update === 'motivo') {
      const { error } = await service.updateMotivo({
        id: objetoUpdate.id,
        descricao: values.descricao,
        status_ativo: 1,
      });
      isError = error;
    } else if (update === 'tipoPerfil') {
      const { error } = await service.updateTipoPerfil({
        id: objetoUpdate.id,
        descricao: values.descricao,
        status_ativo: 1,
      });
      isError = error;
    }

    if (isError) {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    } else {
      enqueueSnackbar(`Salvo com sucesso!`, {
        title: 'Cadastro salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }

    setLoading(false);
    afterFinish();
    controlModal();
    console.log('cejh', isError)
    console.log('MEU DEUS1', objetoUpdate.id)

  };
  return (
    <>
      <Modal
        title={title}
        size="medio"
        openModal={openModal}
        controlModal={controlModal}
      >
        <Spin spinning={loading}>
          <Form
            size="middle"
            layout="horizontal"
            scrollToFirstError={true}
            onFinish={onFinish}
            labelAlign="left"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              origem_macro_id: objetoUpdate?.origem_macro_id,
              descricao: objetoUpdate?.descricao,
            }}
          >
            {update === 'especifica' && (
              <Grid item sm={6} xs={6}>
                <Form.Item
                  name="origem_macro_id"
                  label="Origem Macro"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma Origem Macro',
                    },
                  ]}
                >
                  <SelectAntd placeholder="Origem Macro" disabled>
                    {origensMacro.map(item => (
                      <SelectAntd.Option key={item.id} value={item.id}>
                        {item.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
            )}

            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Descricao"
                hasFeedback
                name="descricao"
                rules={[{ required: true, message: 'Campo obrigatorio' }]}
              >
                <Input placeholder="Descricao" />
              </Form.Item>
              <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
                <ButtonComponent color="primary" fullWidth type="submit">
                  <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                </ButtonComponent>
              </Grid>
            </Grid>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default OrigemMacro;
