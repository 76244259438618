import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Edit, Delete, Add, WarningOutlined } from '@material-ui/icons';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import TableCustomKeysNoPagination from '@src/components/Shared/Tables/TableCustomKeysNoPagination';

import {
  Form,
  Select as SelectAntd,
  Spin,
  Menu,
  Dropdown,
  Button,
  Empty,
  Modal,
} from 'antd';

import ButtonComponent from '@components/Shared/Buttons/Button';

import Tag from '@components/Shared/Tag';
import ModalCreate from './components/Modals/create';
import ModalUpdate from './components/Modals/update';

import { TitlePage } from '@styles/styles.global';
import { useLoading } from '@hooks/LoadingContext';

import { useAuth } from '@hooks/AuthenticateContext';

import Services, { IData, IUpdate } from './services';
import InvisibleContent from '@src/components/Shared/InvisibleContent';

const TipoPerfil: React.FC = () => {
  const { removeLoading, setValues } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const [tiposPerfil, setTiposPerfil] = useState<IData[]>([]);
  const [origensMacro, setOrigensMacro] = useState<IData[]>([]);
  const [origensEspecificas, setOrigensEspecificas] = useState<IData[]>([]);
  const [motivos, setMotivos] = useState<IData[]>([]);
  const [firstLoading, setFirstLoading] = useState(true);
  const [modalOrigemMacro, setModalOrigemMacro] = useState(false);
  const [modalOrigemEspecifica, setModalOrigemEspecifica] = useState(false);
  const [modalMotivo, setModalMotivo] = useState(false);
  const [tiposPerfilModal, setTiposPerfilModal] = useState(false);

  const { user, signOut } = useAuth();

  const [modalUpdate, setModalUpdate] = useState(false);
  const [objetoUpdate, setObjetoUpdate] = useState<any>();
  const [tipoUpdateAndDel, setTipoUpdateAndDel] = useState<any>({} as IUpdate);
  const [loading, setLoading] = useState(false);

  const [openOrigemMacro, setOpenOrigemMacro] = useState(false);
  const [openOrigemEspecifica, setOpenOrigemEspecifica] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [openMotivo, setOpenMotivo] = useState(false);
  const [status_ativo, setStatus_ativo] = useState('Perfil');

  const [modalMotivoUpdate, setModalMotivoUpdate] = useState(false);
  const [modalOrigemEspecificaUpdate, setModalOrigemEspecificaUpdate] = useState(false);

  const [origemEspecifica, setOrigemEspeficica] = useState('especifica');
  const [motivo, setMovito] = useState('motivo');







  const getTiposPerfil = async () => {
    setValues(true);
    const service = new Services();
    const responseTiposPerfil = await service.loadTipoPerfil();

    if (!responseTiposPerfil.error && responseTiposPerfil.response) {
      setTiposPerfil(responseTiposPerfil.response);
    }
  };

  const getOrigemMacro = async () => {
    const service = new Services();
    const responseOrigemMacro = await service.loadOrigemMacro();

    if (!responseOrigemMacro.error && responseOrigemMacro.response) {
      setOrigensMacro(responseOrigemMacro.response);
    }
  };

  const getOrigemEspecifica = async () => {
    const service = new Services();
    const responseOrigemEspecifica = await service.loadOrigemEspecifica();

    if (!responseOrigemEspecifica.error && responseOrigemEspecifica.response) {
      setOrigensEspecificas(responseOrigemEspecifica.response);
    }
  };

  const getMotivos = async () => {
    const service = new Services();

    const responseMotivos = await service.loadMotivos();

    if (!responseMotivos.error && responseMotivos.response) {
      setMotivos(responseMotivos.response);
    }
    removeLoading();
  };

  const initialDatas = async () => {
    await getTiposPerfil();
    await getOrigemMacro();
    await getOrigemEspecifica();
    await getMotivos();
    setFirstLoading(false);
  };


  useEffect(() => {
    initialDatas();
    window.document.title = 'CRM - Classificação de perfis';
  }, []);


    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  const orchestratorRouteDelete = async ({ id, tipoDelete }: any) => {
    let isError = true;
    const services = new Services();

    if (tipoDelete === 'motivo') {
      const { error } = await services.deleteMotivo({ id });
      isError = error;
    } else if (tipoDelete === 'especifica') {
      const { error } = await services.deleteOrigemEspecifica({ id });
      isError = error;
    } else if (tipoDelete === 'macro') {
      const { error } = await services.deleteOrigemMacro({ id });
      isError = error;
    } else if (tipoDelete === 'tipoPerfil') {
      const { error } = await services.deleteTipoPerfil({ id });
      isError = error;
    }

    if (isError) {
      enqueueSnackbar('Erro ao tentar deletar', {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      return;
    }

    enqueueSnackbar(`Deletado com sucesso!`, {
      title: 'Cadastro salvo com sucesso!',
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

    await initialDatas();
  };

  function showDeleteConfirm({ objetoDelete, tipoDelete }: any) {
    Modal.confirm({
      title: 'Certeza que deseja excluir esse item?',
      icon: <WarningOutlined />,
      content: '',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      async onOk() {
        await orchestratorRouteDelete({
          id: objetoDelete.id,
          tipoDelete: tipoDelete,
        });
      },
      onCancel() {},
    });
  }

  console.log("CACHORRO", tipoUpdateAndDel)


  return (
    <>
      <ModalCreate
        title="Nova origem macro"
        afterFinish={getOrigemMacro}
        openModal={modalOrigemMacro}
        controlModal={() => setModalOrigemMacro(false)}
        create="macro"
      />

      <ModalCreate
        title="Nova origem específica"
        afterFinish={getOrigemEspecifica}
        openModal={modalOrigemEspecifica}
        controlModal={() => setModalOrigemEspecifica(false)}
        create="especifica"
      />

      <ModalCreate
        title="Novo motivo"
        afterFinish={getMotivos}
        openModal={modalMotivo}
        controlModal={() => setModalMotivo(false)}
        create="motivo"
      />

      <ModalCreate
        title="Novo tipo de perfil"
        afterFinish={getTiposPerfil}
        openModal={tiposPerfilModal}
        controlModal={() => setTiposPerfilModal(false)}
        create="tipoPerfil"
      />

      <ModalUpdate
        title="Editar origem especifica"
        afterFinish={getOrigemEspecifica}
        openModal={modalOrigemEspecificaUpdate}
        controlModal={() => setModalOrigemEspecificaUpdate(false)}
        update={tipoUpdateAndDel}
        objetoUpdate={objetoUpdate}
      />



      <ModalUpdate
        title="Editar motivo"
        afterFinish={getMotivos}
        openModal={modalMotivoUpdate}
        controlModal={() => setModalMotivoUpdate(false)}
        update={tipoUpdateAndDel}
        objetoUpdate={objetoUpdate}
      />

      {/* // HEADER PAGE */}

      <Grid container alignItems="center" justify="flex-start">
        <Grid container item sm={12}>
          <Grid item sm={6} xs={12}>
            <TitlePage style={{ paddingTop: 6 }}>Classificação de perfis</TitlePage>
          </Grid>
        </Grid>
      </Grid>
      {/* // HEADER PAGE */}


      {/* // CARDS */}

      <Grid container item lg={12} sm={12} xs={12} style={{ paddingTop: 10 }}>

        <Grid container item lg={4} sm={4} xs={12} style={{ paddingTop: 15 }}>

          <Grid item lg={12} style={{ padding: 22 }}>
            <Grid item lg={12} style={{ paddingTop: 2 }}>
              <ButtonComponent
                color={status_ativo === 'Perfil' ? 'primary' : 'secondary'}
                style={{width: 150}}
                onClick={() => (setOpenOrigemEspecifica(false), setOpenMotivo(false), setOpenOrigemMacro(false), setOpenPerfil(true), setStatus_ativo('Perfil'))}
              >
                Perfil
              </ButtonComponent>
            </Grid>

            <Grid item lg={12} style={{ paddingTop: 10 }}>
            <ButtonComponent
              color={status_ativo === 'Macro' ? 'primary' : 'secondary'}
              style={{width: 150}}
              onClick={() => (setOpenOrigemEspecifica(false), setOpenMotivo(false), setOpenPerfil(false), setOpenOrigemMacro(true), setStatus_ativo('Macro'))}
            >
              Origem macro
            </ButtonComponent>
            </Grid>


            <Grid item lg={12} style={{ paddingTop: 10 }}>
            <ButtonComponent
              color={status_ativo === 'Especifica' ? 'primary' : 'secondary'}
              style={{width: 150}}
              onClick={() => (setOpenPerfil(false), setOpenMotivo(false), setOpenOrigemMacro(false), setOpenOrigemEspecifica(true), setStatus_ativo('Especifica'))}

            >
              Origem especifica
            </ButtonComponent>
            </Grid>

            <Grid item lg={12} style={{ paddingTop: 10}}>
            <ButtonComponent
              color={status_ativo === 'Motivo' ? 'primary' : 'secondary'}
              style={{width: 150}}
              onClick={() => (setOpenPerfil(false), setOpenOrigemEspecifica(false), setOpenOrigemMacro(false), setOpenMotivo(true), setStatus_ativo('Motivo'))}
            >
              Motivo
            </ButtonComponent>
            </Grid>



          </Grid>



        </Grid>

        {/* // TABELA E ADD NOVO  */}
        <Grid container item lg={8} sm={8} xs={12} style={{ padding: 0 }}>

          <InvisibleContent visible={openOrigemMacro === true}>

            <Grid item lg={12} style={{ padding: 0 }}>
              <Grid item lg={8} xs={1} >
                <ButtonComponent
                  color='secondary'
                  onClick={() => setModalOrigemMacro(true)}
                >
                  <Add /> Adicionar
                </ButtonComponent>
              </Grid>

              <Grid item lg={8} xs={12} style={{ paddingTop: 1 }}>
                <TableCustomKeysNoPagination
                  data={origensMacro}
                  keys={['id', 'descricao']}
                  loading={loading}
                  columns={[
                    {
                      id: 'ID',
                      descricao: 'Descrição',
                    },
                  ]}
                  Actions={[
                  ]}
                />
              </Grid>
            </Grid>
          </InvisibleContent>

           <InvisibleContent visible={openOrigemEspecifica === true}>

            <Grid item lg={12} style={{ padding: 0 }}>
              <Grid item lg={6} xs={1} >
                <ButtonComponent
                  color='secondary'
                  onClick={() => setModalOrigemEspecifica(true)}
                >
                  <Add /> Adicionar
                </ButtonComponent>
              </Grid>
              <Grid item lg={8} xs={12} style={{ paddingTop: 1 }}>
                <TableCustomKeysNoPagination
                  data={origensEspecificas}
                  keys={['id', 'descricao']}
                  loading={loading}
                  columns={[
                    {
                      id: 'ID',
                      descricao: 'Descrição',
                    },
                  ]}
                  Actions={[
                    {
                      Button: (item: IData) => {
                        return (
                          <IconButton
                          onClick={() => {
                            setModalOrigemEspecificaUpdate(true);
                            setObjetoUpdate(item);
                            setTipoUpdateAndDel(origemEspecifica);
                          }}
                        >
                          <Edit fontSize="small" />
                          </IconButton>
                        );
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </InvisibleContent>

           <InvisibleContent visible={status_ativo === 'Perfil'}>

            <Grid item lg={12} style={{ padding: 0 }}>
              <Grid item lg={2} xs={1} >
                <ButtonComponent
                  color='secondary'
                  onClick={() => setTiposPerfilModal(true)}
                >
                  <Add /> Adicionar
                </ButtonComponent>
              </Grid>
              <Grid item lg={8} xs={12} style={{ paddingTop: 1 }}>
                <TableCustomKeysNoPagination
                  data={tiposPerfil}
                  keys={['id', 'descricao']}
                  loading={loading}
                  columns={[
                    {
                      id: 'ID',
                      descricao: 'Descrição',
                    },
                  ]}
                  Actions={[
                  ]}
                />
              </Grid>
            </Grid>
          </InvisibleContent>

           <InvisibleContent visible={openMotivo === true}>

            <Grid item lg={12} style={{ padding: 0 }}>
              <Grid item lg={2} xs={1} >
                <ButtonComponent
                  color='secondary'
                  onClick={() => setModalMotivo(true)}
                >
                  <Add /> Adicionar
                </ButtonComponent>
              </Grid>
              <Grid item lg={8} xs={12} style={{ paddingTop: 1 }}>
                <TableCustomKeysNoPagination
                  data={motivos}
                  keys={['id', 'descricao']}
                  loading={loading}
                  columns={[
                    {
                      id: 'ID',
                      descricao: 'Descrição',
                    },
                  ]}
                  Actions={[
                    {
                      Button: (item: any) => {
                        return (
                          <IconButton
                          onClick={() => {
                            setModalMotivoUpdate(true);
                            setObjetoUpdate(item);
                            setTipoUpdateAndDel(motivo);
                          }}
                        >
                          <Edit fontSize="small" />
                          </IconButton>
                        );
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </InvisibleContent>

        </Grid>
      </Grid>
    </>
  );
};

export default TipoPerfil;
