import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { SearchOutlined, TuneOutlined, Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';

import type { CheckboxChangeEvent } from 'antd/es/checkbox';

import { TitlePage } from '@styles/styles.global';

import TableActions from '@components/Shared/Tables/TableCustomKeysSemCount';
import TableWithTotalItens from '@src/components/Shared/Tables/TableWithTotalItensSemCount';

import ButtonComponent from '@components/Shared/Buttons/Button';


import { Select as SelectAntd, Form, Radio, Checkbox} from 'antd';
import InvisibleContent from '@components/Shared/InvisibleContent';

import DatePicker from '@src/components/Shared/DatePicker';
import Button from '@components/Shared/Buttons/Button';

import PageListPessoasServices, {
  IConsultaEstado,
  IConsultaPessoa,
  IConsultaFaixaEtaria,
  IConsultaOcorrencia,
  IConsultaAniversariante,
  IConsultaDoacaoEmail,
  IConsultaOrigemDoacao,
  IConsultaDoacaoValor,
  IConsultaDoacaoQuantidade,
  IFormaContribuicao,
  IPerfil,
  IOrigemEspecifica,
  ICorrespondencia,
  IConvenio,
  IConsultaPrimeirosDoadoresByPeriodo,
  IConsultaFormaDoacao,
  IConsultaDoacoesPFePJ,
  IConsultaDoacoesPrevisaoCredito,
  IConsultaDoacoesByPeriodo,
  IConsultaBoletoStatus,
  IConsultaCadastroReativados,
  IConsultaPalitagens,
  IConsultaMalaMensalEnviada,
  IConsultaArrecadacoesDebito,
  IConsultaRemessaRetorno,
  IConsultaPrimeiraDoacaoPessoaMes,
  IConsultaCadastroOperador,
  IConsultaInativosSemContrib,
  IOrigemDoacao,
} from './service';
import { subDays } from 'date-fns';

import UFs from '@components/Shared/BuscaEstados/UF2.json';

import { useLoading } from '@hooks/LoadingContext';
import Filtros from '@src/components/Shared/Filtros';
import { useAuth } from '@hooks/AuthenticateContext';
import { couldStartTrivia } from 'typescript';

const columnsLabelEstado = {
  cidade: 'Cidade',
  quantidade_cadastros_ativos: 'Quant. de cadastros',
};

const columnsLabelPessoa = {
  id_pessoa: 'ID pessoa',
  nome: 'Nome',
  email: 'Email',
  tipo_perfil: 'Tipo perfil',
  nome_fantasia: 'Nome fantasia',
  motivo_cadastro: 'Motivo',
  origem_cadasro: 'Origem',
  tipo_correspondencia: 'Corresp.',
  forma_contribuicao: 'Contrib.'
};

const columnsLabelFaixaEtaria = {
  item1: 'Faixa etária',
  item2: 'Quant. feminino',
  item3: 'Quant. masculino'
};

const columnsLabelOcorrencia = {
  id_pessoa: 'ID pessoa',
  nome_pessoa: 'Nome',
  status_ocorrencia: 'Status ocorrência',
  convenio: 'Convênio',
  data_vencimento: 'Data vencimento'
};

const columnsLabelAniversariante = {
  id_pessoa: 'ID pessoa',
  nome: 'Nome',
  tipo_perfil: 'Tipo perfil',
  data_aniversario: 'Data nascimento'
};

const columnsLabelDoacaoEmail = {
  id_pessoa: 'ID pessoa',
  nome: 'Nome',
  email: 'E-mail',
  valor_doado: 'Valor total doado',
  vezes_doadas: 'Quant. doação',
};

const columnsLabelOrigemDoacao = {
  item1: 'Forma de contribuição',
  item2: 'Quant. doação',
  item3: 'Valor total da doação',
};

const columnsLabelDoacaoValor = {
  item1: 'Faixa de doação',
  item2: 'Quant. doações',
  item3: 'Quant. doadores',
  item4: 'Valor total',
};

const columnsLabelDoacaoQuantidade = {
  item1: 'Quant. de doação',
  item2: 'Quant. doadores',
  item3: 'Quant. doações',
  item4: 'Valor total',
};

const columnsLabelPrimeirosDoadores = {
  id_pessoa: 'ID pessoa',
  nome: 'Nome',
  documento: 'Documento',
  tipo_perfil: 'Tipo perfil',
  forma_contribuicao: 'Forma Contrib.',
  data_cadastro: 'Data cadastro doação',
  data_doacao: 'Data doação',
  valor_doacao: 'Valor doação',
};

const columnsLabelFormaDoacao = {
  item1: 'Forma doação',
  item2: 'Quant. doações',
  item3: 'Valor total',
  item4: 'Ticket médio',
};

const columnsLabelDoacoesPFePJ = {
  item1: 'Forma de doação',
  item2: 'Quant. doações',
  item3: 'Ticket médio',
}

const columnsLabelDoacoesByPeriodo = {
  dataCadastro: 'Data cadastro',
  dataCredito: 'Data Credito',
  idDoacao: 'ID Doação',
  idPessoa: 'ID Pessoa',
  nomePessoa: 'Nome Pessoa',
  //nomeUsuarioCadastroDoacao: 'Usuario',
  tarifa: 'Tarifa',
  valorDoacao: 'Valor doação',
  origemDoacao: 'Origem Doação',
}

const columnsLabelDoacoesPrevisaoCredito = {
  item1: 'Forma de contribuição',
  item2: 'Quant. doações',
  item3: 'Valor total',
}

const columnsLabelDoacoesBoletoStatus = {
  usuario: 'Usuário',
  numeroEmisssao: 'Nº Emissão',
  dataEmissaoBoleto: 'Data Emissão',
  dataVencimentoBoleto: 'Data Vencimento',
  statusBoleto: 'Status',
  idCrm: 'ID pessoa',
  nomePessoa: 'Nome',
  estrategia: 'Estratégia',
}

const columnsLabelCadastroReativados = {
  idPessoa: 'ID pessoa',
  nomePessoa: 'Nome',
  origemCadastro: 'Origem cadastro',
  motivoCadastro: 'Motivo cadastro',
  formaContribuicao: 'Forma de contribuição',
  tipoCorrespondencia: 'Tipo correspondência',
}

const columnsLabelListaPalitagens = {
  //codigoUsuario: 'ID pessoa',
  nomeUsuario: 'Nome usuário',
  idCadastro: 'ID pessoa',
  nomeCadastro: 'Nome',
  diaLigacao: 'Dia ligação',
  horarioInicioLigacao: 'Início ligação',
  horarioFimLigacao: 'Fim ligação',
  observacao: 'Observação',
  statusLigacao: 'Status ligação',
  estrategiaLigacao: 'Estratégia'
}

const columnsLabelMalaMensalEnviada = {
  tipoEnvio: 'Tipo envio',
  qntDoacoes: 'Quant. doações',
  valorTotalDoacao: 'Valor total doações',
  mediaDoacao: 'Média doações',
}

const columnsLabelArrecadacoesDebito = {
  id: 'ID pessoa',
  nome: 'Nome',
  vencimento: 'Vencimento',
  valor: 'Valor doação',
  descricao: 'Status',
  banco: 'Banco'
}

const columnsLabelRemessaRetorno = {
  convenio: 'ID convênio',
  banco: 'Banco',
  arquivo: 'Arquivo',
  boletosAguardandoRetorno: 'Aguardando retorno',
  boletosAguardandoEnvio: 'Aguardando Envio',
  boletosRejeitados: 'Rejeitados',
  boletosPagos: 'Pagos',
  boletosRegistrados: 'Registrados',
  boletosVencidos: 'Vencidos',
  aguardandoRetorno: 'Aguardando Retorno',
  //status: 'Status',
  //descricao: 'Status',
}

const columnsLabelPrimeiraDoacaoPessoaMes = {
  nome: 'Nome',
  nome_fantasia: 'Nome fantasia',
  documento: 'Documento',
  valor_primeira_doacao: 'Valor doação',
  data_primeira_doacao: 'Data doação',
}

const columnsLabelCdadastroOperador = {
  codigoUsuario: 'ID usuário',
  nomeUsuario: 'Nome usuário',
  data_cadastro: 'Data cadastro',
  idCadastro: 'ID pessoa',
  nomeCadastro: 'Nome',
  apelido: 'Apelido',
  documento: 'Documento',
  tipo_perfil: 'Tipo perfil',
  origem_cadastro: 'Origem cadastro',
  forma_contribuicao: 'Forma contrib.',
  recorrente: 'Recorrente',
  tipo_correspondencia: 'Tipo corresp.',
  endereco: 'Endereço',
  bairro: 'Bairro',
  complemento: 'Complemento',
  cidade: 'Cidade',
  estado: 'Estado',
};

const columnsLabelInativosSemFormaContrib= {
  id_pessoa: 'ID',
  nomeCompleto: 'Nome',
  FormaContribuicao: 'Forma contrib.',
  datadecadastro: 'Data cadastro',
}

const mesAtual = new Date().getMonth();

const anoAtual = new Date().getFullYear();
const anoPassado = anoAtual - 1;



const tiposFiltros = ['Aniversariantes', 'Arrecadações débito por período', 'Cadastros ativos por período', 'Cadastros inativos sem forma de contribuição','Cadastros por cidade',
'Cadastros por faixa etária e gênero', 'Cadastros por operador por período', 'Cadastros reativados por período', 'Cards por período', 'Doadores com e-mail',
'Doações por faixa de valor', 'Doações por período', 'Doações por quantidade de doação', 'Envio de remessa e retorno', 'Mala mensal - quantitativo de doações', 'Ocorrência de doação', 'Origem doações',
'Palitagens por período', 'Previsão de doações', 'Primeira doação no mês por período', 'Status boleto por período', 'Ticket médio PF e PJ',
'Ticket médio por forma de doação'];

interface IOcorrencia {
  id: number;
  descricao: string;
}

interface IMesMalaMensal {
  id: number;
  descricao: string;
}

interface IAnoMalaMensal {
  id: number;
  //descricao: string;
}


const DESCRICAO_BOLETO2 = 'boleto';
//const DESCRICAO_DEBITO = 'débito automático';

const DESCRICAO_BOLETO = 'boleto';
const DESCRICAO_DEBITO = 'débito automático';

const Consultas: React.FC = () => {
  const { setValues, removeLoading } = useLoading();
  const [formRef] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth()

  const [dataEstado, setDataEstado] = useState<IConsultaEstado[]>([]);
  const [dataPessoa, setDataPessoa] = useState<IConsultaPessoa[]>([]);
  const [dataFaixaEtaria, setDataFaixaEtaria] = useState<IConsultaFaixaEtaria[]>([]);
  const [dataOcorrencia, setDataOcorrencia] = useState<IConsultaOcorrencia[]>([]);
  const [dataAniversariante, setDataAniversariante] = useState<IConsultaAniversariante[]>([]);
  const [dataDoacaoEmail, setDataDoacaoEmail] = useState<IConsultaDoacaoEmail[]>([]);
  const [dataOrigemDoacao, setDataOrigemDoacao] = useState<IConsultaOrigemDoacao[]>([]);
  const [dataOrigemDoacaoByPeriodo, setDataOrigemDoacaoByPeriodo] = useState<IConsultaDoacoesByPeriodo[]>([]);
  const [dataDoacaoValor, setDataDoacaoValor] = useState<IConsultaDoacaoValor[]>([]);
  const [dataDoacaoQuantidade, setDataDoacaoQuantidade] = useState<IConsultaDoacaoQuantidade[]>([]);
  const [dataPrimeirosDoadores, setDataPrimeirosDoadoresByPeriodo] = useState<IConsultaPrimeirosDoadoresByPeriodo[]>([]);
  const [dataFormaDoacao, setDataFormaDoacao] = useState<IConsultaFormaDoacao[]>([]);
  const [dataDoacoesPFePJ, setDataDoacoesPFePJ] = useState<IConsultaDoacoesPFePJ[]>([]);
  const [dataPrevisaoDoacao, setDataPrevisaoDoacao] = useState<IConsultaDoacoesPrevisaoCredito[]>([]);
  const [dataStatusBoleto, setDataStatusBoleto] = useState<IConsultaBoletoStatus[]>([]);
  const [dataCadastroReativados, setDataCadastroReativados] = useState<IConsultaCadastroReativados[]>([]);
  const [dataPalitagens, setDataPalitagens] = useState<IConsultaPalitagens[]>([]);
  const [dataMalaMensalEnviada, setDataMalaMensalEnviada] = useState<IConsultaMalaMensalEnviada[]>([]);
  const [dataArrecadacoesDebito, setDataArrecadacoesDebito] = useState<IConsultaArrecadacoesDebito[]>([]);
  const [dataRemessaRetorno, setDataRemessaRetorono] = useState<IConsultaRemessaRetorno[]>([]);
  const [dataPrimeiraDoacaoPessoaMes, setDataPrimeiraDoacaoPessoaMes] = useState<IConsultaPrimeiraDoacaoPessoaMes[]>([]);
  const [dataCadastroOperador, setDataCadastroOperador] = useState<IConsultaCadastroOperador[]>([]);
  const [inativosSemContrib, setInativosSemContrib] = useState<IConsultaInativosSemContrib[]>([]);


  const [dataLinkArquivo, setDataLinkArquivo] = useState('');

  const [loadingButtonBuscar, setLoadingButtonBuscar] = useState(false);


  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [conveniosTodos, setConveniosTodos] = useState<IConvenio[]>([]);


  const hoje = new Date();
  hoje.getMonth();

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItens, setTotalItems] = useState(0);

  const [tipoFiltroSelected, setTipoFiltroSelected] = useState<
    'Arrecadações débito por período' | 'Doadores com e-mail' | 'Cadastros por cidade' | 'Aniversariantes' | 'Cadastros ativos por período'
    | 'Cadastros inativos sem forma de contribuição'|  'Cadastros por faixa etária e gênero' | 'Cadastros por operador por período' | 'Cadastros reativados por período' | 'Doações por faixa de valor'
    | 'Doações por período' | 'Doações por quantidade de doação' | 'Envio de remessa e retorno' | 'Mala mensal - quantitativo de doações' | 'Ocorrência de doação'
    | 'Origem doações' |   'Palitagens por período' | 'Primeira doação no mês por período' | 'Previsão de doações' | 'Cards por período'
    | 'Status boleto por período' |'Ticket médio PF e PJ' | 'Ticket médio por forma de doação'>();

    const [mesMalaMensal, setMesMalaMensal] = useState<IMesMalaMensal[]>([
      { id: 1, descricao: 'Janeiro' },
      { id: 2, descricao: 'Fevereiro' },
      { id: 3, descricao: 'Março' },
      { id: 4, descricao: 'Abril' },
      { id: 5, descricao: 'Maio' },
      { id: 6, descricao: 'Junho' },
      { id: 7, descricao: 'Julho' },
      { id: 8, descricao: 'Agosto' },
      { id: 9, descricao: 'Setembro' },
      { id: 10, descricao: 'Outubro' },
      { id: 11, descricao: 'Novembro' },
      { id: 12, descricao: 'Dezembro' },
    ]);

    const [anoMalaMensal, setAnoMalaMensal] = useState<IAnoMalaMensal[]>([
      { id: anoPassado },
      { id: anoAtual},

    ]);



    const [idFormaContribuicao2, setIdFormaContribuicao2] = useState<number>(0);
    const [idConvenio, setIdConvenio] = useState<number>(0);
    
    const [tipoConvenio, setTipoConvenio] = useState<string>('BOLETO');

    const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [tiposPerfil, setTiposPerfil] = useState<IPerfil[]>([]);
  const [origensEspecificas, setOrigensEspecificas] = useState<
    IOrigemEspecifica[]
  >([]);
  const [correspondencia, setCorrespondencia] = useState<ICorrespondencia[]>([]);

  const [formaContribuicaoSelected, setFormaContruibuicaoSelected] =
  useState<number>(0);

  const [anoSelected, setAnoSelected] =
  useState<number>(0);


  const [periodo, SetPerido] = useState<any>([
    subDays(new Date(), 14),
    new Date(),
  ]);
  const [idFormaContribuicao, setIdFormaContribuicao] = useState<number>(0);
  const [idTipoPerfil, setIdTipoPerfil] = useState<number>(0);

  const [idCorrespondencia, setIdCorrespondencia] = useState<number>(0);
  const [estado, setEstado] = useState<string>("");
  const [ocorrencia, setOcorrencia] = useState<string>("");
  const [checkTipoFormContribuicao, setCheckTipoFormContribuicao] =
  useState('');
  const [mesEstrategiaMensal, setMesEstrategiaMensal] = useState<string>("");
  const [anoEstrategiaMensal, setAnoEstrategiaMensal] = useState<number>(0);
  const [origemDoacao, setOrigemDoacao] = useState <IOrigemDoacao[]>([]);
  const [idOrigemDoacao, setIdOrigemDoacao] = useState<number>(0);



  const [filtros, setFiltros] = useState(false);

  const [checkedGerarCSV, setCheckedGerarCSV] = useState(false);


  const filterCheckTipoFormContribuicao = (id: any) => {
    const forma = formasContribuicao.filter(x => x.id === id);

    if (forma.length > 0) setCheckTipoFormContribuicao(forma[0].descricao);
  };

  const loadOrigemDoacao = async () => {
    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.loadOrigemDoacao();
    if (!error && response) {
      setOrigemDoacao(response);
    }
    removeLoading();
  };

  const setValuesOrErrorEstado = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataEstado(response.estados);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorPessoa = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataPessoa(response.pessoas);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorFaixaEtaria = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataFaixaEtaria(response.faixas);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorOcorrencia = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataOcorrencia(response.ocorrencias);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorAniversariantes= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataAniversariante(response.aniversariantes);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }

  };

  const setValuesOrErrorDoacaoEmail= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataDoacaoEmail(response.doacaoEmail);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorOrigemDoacao= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataOrigemDoacao(response.origemDoacao);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorDoacaoValor= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataDoacaoValor(response.doacaoValor);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorDoacoesByPeriodo= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataOrigemDoacaoByPeriodo(response.doacoesByPeriodo);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorDoacaoQuantidade= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataDoacaoQuantidade(response.doacaoQuantidade);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorPrimeirosDoadores= (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataPrimeirosDoadoresByPeriodo(response.doacaoPrimeirosDoadores);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorFormaDoacao = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataFormaDoacao(response.doacoesFormaDoacao);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorDoacaoPFePJ = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataDoacoesPFePJ(response.doacoesPFePj);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorPrevisaoDoacao = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataPrevisaoDoacao(response.doacoesPrevisaoCredito);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorStatusBoleto = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataStatusBoleto(response.doacoesStatusBoleto);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorCadastroReativados = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataCadastroReativados(response.cadastroReativados);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorListaPalitagens = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataPalitagens(response.listaPalitagens);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorMalaMensalEnviada = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataMalaMensalEnviada(response.malaMensalEviada);
      setDataLinkArquivo(response.link_arquivo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorArrecadacoesDebito = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataArrecadacoesDebito(response.arrecadacoesDebito);
      setDataLinkArquivo(response.link_arquivo)
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorRemessaRetorno = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataRemessaRetorono(response.remessaRetorno);
      setDataLinkArquivo(response.link_arquivo)
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorPrimeiraDoacaoPessoaMes = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataPrimeiraDoacaoPessoaMes(response.primeiraDoacaoPessoaMes);
      setDataLinkArquivo(response.link_arquivo)
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorCadastroOperador = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setDataCadastroOperador(response.cadastroOperador);
      setDataLinkArquivo(response.link_arquivo)
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const setValuesOrErrorInativosSemContrib = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setInativosSemContrib(response.inativosSemFormaContrib);
      setDataLinkArquivo(response.link_arquivo)
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };


  const loadFormaContribuicao = async () => {
    const services = new PageListPessoasServices();
    const { error, response } = await services.loadFormaContribuicao();

    if (!error && response) {
      setFormasContribuicao(response);
      // const foundFormaContribuicao = response.find(
      //   x => x.id === formaContribuicaoSelected,
      // );

      // if (!foundFormaContribuicao) {
      //   return 0;
      // }

      // if (foundFormaContribuicao.descricao.toLowerCase() === DESCRICAO_DEBITO) {
      //   setRangeData([DEBITO_NORMAL_DATA, DEBITO_NORMAL_DATA]);
      // }

      setFormaContruibuicaoSelected(Number(response[0].id));

      return Number(response[0].id);
    } else {
      return 0;
    }
  };

  async function loadTiposPerfil() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.GetTiposPerfil();

    if (!error && response) {
      setTiposPerfil(response);
    }
  }

  async function loadOrigensEspecificas() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } =
      await pageListPessoasServices.loadOrigemEspecifica();

    if (!error && response) {
      setOrigensEspecificas(response);
    }
  }

  async function loadCorrespondencia() {
    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.loadCorrespondencia();

    if (!error && response) {
      setCorrespondencia(response);
    }
    removeLoading();
  }

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setItemsPerPage(itemsPage);

    if (tipoFiltroSelected === 'Cadastros por cidade' ) {

      onChangeConsultaEstado(page, itemsPage);

      return;
    }

    if (tipoFiltroSelected === 'Ocorrência de doação') {

      onChangeConsultaOcorrencia(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Aniversariantes') {

      onChangeConsultaAniversariantes(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected ===  'Doadores com e-mail') {

      onChangeConsultaDoacaoEmail(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected ===  'Origem doações') {

      onChangeConsultaOrigemDoacao(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Cadastros por faixa etária e gênero') {

      onChangeConsultaFaixaEtaria(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Doações por faixa de valor') {

      onChangeConsultaDoacaoValor(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Doações por período') {

      onChangeConsultaDoacoesByPeriodo(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Doações por quantidade de doação') {

      onChangeConsultaDoacaoQuantidade(page, itemsPage);
      return;
    }


    if (tipoFiltroSelected === 'Cadastros ativos por período') {

      onChangeConsultaPessoa(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Cards por período') {

      onChangeConsultaPrimeirosDoadores(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Ticket médio por forma de doação') {

      onChangeConsultaFormaDoacao(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Ticket médio PF e PJ') {

      onChangeConsultaDoacaoPFePJ(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Previsão de doações') {

      onChangeConsultaPrevisaoDoacao(page, itemsPage, checkedGerarCSV);
      return;
    }

    if (tipoFiltroSelected === 'Status boleto por período') {

      onChangeConsultaStatusBoleto(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Cadastros reativados por período') {

      onChangeConsultaCadastrosReativados(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Palitagens por período') {

      onChangeConsultaListaPalitagens(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Mala mensal - quantitativo de doações') {

      onChangeConsultaMalaMensalEnviada(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Arrecadações débito por período') {

      onChangeConsultaArrecadacoesDebito(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Envio de remessa e retorno') {

      onChangeConsultaRemessaRetorno(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Primeira doação no mês por período') {

      onChangeConsultaPrimeiraDoacaoPessoaMes(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Cadastros por operador por período') {

      onChangeConsultaCadastroOperador(page, itemsPage);
      return;
    }

    if (tipoFiltroSelected === 'Cadastros inativos sem forma de contribuição') {

      onChangeConsultaInativosSemContrib(page, itemsPage);
      return;
    }

  }

  const onChangeConsultaEstado = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaEstado({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: idFormaContribuicao,
      id_tipo_correspondencia: idCorrespondencia,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia: ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorEstado(error, response);
  };

  const onChangeConsultaPessoa = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaPessoa({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: idFormaContribuicao,
      id_tipo_correspondencia: idCorrespondencia,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia:  ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorPessoa(error, response);
  };

  const onChangeConsultaFaixaEtaria = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaFaixaEtaria({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: idFormaContribuicao,
      id_tipo_correspondencia: idCorrespondencia,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia: ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorFaixaEtaria(error, response);
  };

  const onChangeConsultaOcorrencia = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaOcorrencia({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: formaContribuicaoSelected,
      id_tipo_correspondencia: idCorrespondencia,
      id_convenio: idConvenio,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia:  ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorOcorrencia(error, response);
  };

  const onChangeConsultaAniversariantes = async (page: number, itemsPage: number, ) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaAniversariante({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: idFormaContribuicao,
      id_tipo_correspondencia: idCorrespondencia,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia:  ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorAniversariantes(error, response);
  };

  const onChangeConsultaDoacaoEmail = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacaoEmail({
      id_tipo_perfil: idTipoPerfil,
      estado: estado,
      id_forma_contribuicao: idFormaContribuicao,
      id_tipo_correspondencia: idCorrespondencia,
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      tipo_ocorrencia:  ocorrencia,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorDoacaoEmail(error, response);
  };

  const onChangeConsultaOrigemDoacao = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaOrigemDoacao({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorOrigemDoacao(error, response);
  };

  const onChangeConsultaDoacaoValor= async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacaoValor({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorDoacaoValor(error, response);
  };

  const onChangeConsultaDoacoesByPeriodo = async (page: number, itemsPage: number, ) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacoesByPeriodo({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
      origem_doacao: idOrigemDoacao,
  
    });

    setValuesOrErrorDoacoesByPeriodo(error, response);
  };

  const onChangeConsultaDoacaoQuantidade= async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacaoQuantidade({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorDoacaoQuantidade(error, response);
  };

  const onChangeConsultaPrimeirosDoadores = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaPrimeirosDoadoresByPeriodo({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorPrimeirosDoadores(error, response);
  };

  const onChangeConsultaFormaDoacao = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacoesByFormaDoacao({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorFormaDoacao(error, response);
  };

  const onChangeConsultaDoacaoPFePJ = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaDoacoesPFePJ({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorDoacaoPFePJ(error, response);
  };

  const onChangeConsultaPrevisaoDoacao = async (page: number, itemsPage: number, checkedGerarCSV: boolean) => {
    setLoading(true);
    setPage(page);
    //const gerar_arquivo: boolean;

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaPrevisaoCredito(
     // data_inicial: periodo[0],
     // data_final: periodo[1],
      checkedGerarCSV,
     // page_number: page,
      //page_size: itemsPage,
    );

    setValuesOrErrorPrevisaoDoacao(error, response);
  };

  const onChangeConsultaStatusBoleto = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaBoletoStatus({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorStatusBoleto(error, response);
  };

  const onChangeConsultaCadastrosReativados = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaCadastroReativados({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorCadastroReativados(error, response);
  };

  const onChangeConsultaListaPalitagens= async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaPalitagens({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorListaPalitagens(error, response);
  };

  const onChangeConsultaMalaMensalEnviada= async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaMalaMensalEnviada({
      mes_estrategia: mesEstrategiaMensal,
      ano_estrategia: anoSelected,
      gerar_aquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorMalaMensalEnviada(error, response);
  };

  const onChangeConsultaArrecadacoesDebito = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaArrecadacoesDebito({
      data_inicial: periodo[0],
      data_final: periodo[1],
      id_conveio: idConvenio,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorArrecadacoesDebito(error, response);
  };

  const onChangeConsultaRemessaRetorno = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaEnvioRemessaRetorno({
      //data_inicial: periodo[0],
      //data_final: periodo[1],
      id_conveio: idConvenio,
      tipo_convenio: tipoConvenio,
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorRemessaRetorno(error, response);
  };

  const onChangeConsultaPrimeiraDoacaoPessoaMes = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaPrimeiraDoacaoPessoaMes({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorPrimeiraDoacaoPessoaMes(error, response);
  };

  const onChangeConsultaCadastroOperador = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaCadastroPorOperador({
      data_inicial: periodo[0],
      data_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorCadastroOperador(error, response);
  };

  const onChangeConsultaInativosSemContrib = async (page: number, itemsPage: number) => {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();

    const { error, response } = await pageListPessoasServices.buscaConsultaInativosSemContrib({
      data_procura_inicial: periodo[0],
      data_procura_final: periodo[1],
      gerar_arquivo: checkedGerarCSV,
      page_number: page,
      page_size: itemsPage,
    });

    setValuesOrErrorInativosSemContrib(error, response);
  };


    window.document.title = 'CRM - Consultas';

    const onChangeDates = async (dates: any) => {
      SetPerido(dates);
    };


  const handleOpen = () => {
    setFiltros(!filtros);
  };

  const loadConvenios = async (
    idFormaContribuicao: number | string,
  ): Promise<number> => {
    const services = new PageListPessoasServices();
    const { error, response } = await services.GetConvenios(
      idFormaContribuicao,
    );

    if (!error && response) {
      setConvenios(response);
      formRef.setFieldsValue({ convenio: response[0]?.id });

      //console.log('TESTEEE', response);

      return response[0]?.id;
    } else {
      //console.log('ENTROU AQUI');

      formRef.setFieldsValue({ convenio: null });
      return 0;
    }
  };

  const loadTodosConvenios = async () => {
    const services = new PageListPessoasServices();

    const conveniosResponse = await services.GetTodosConvenio();

    if (!conveniosResponse.error && conveniosResponse.response) {
      setConveniosTodos(conveniosResponse.response);
    }
  };


  const gerarPDF = async () => {
    setLoading(true);

    const services = new PageListPessoasServices();

      const { error, response } = await services.GerarPDF()

      if (!error && response) {
        //etDatasSelecionadas([]);
        //loadDebitos(convenioID, codigoMovimento, tipo_debito);
        window.open(response);
        enqueueSnackbar(`Gerado com sucesso!`, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoading(false);
      }

  };

  const onChange = (e: CheckboxChangeEvent) => {
    setCheckedGerarCSV(e.target.checked);
  };

  const onChangeClearButton = () => {
    setLoadingButtonBuscar(false);
    setCheckedGerarCSV(false)
    setDataEstado([]);
    setDataPessoa([]);
    setDataFaixaEtaria([]);
    setDataOcorrencia([]);
    setDataAniversariante([]);
    setDataAniversariante([]);
    setDataDoacaoEmail([]);
    setDataOrigemDoacao([]);
    setDataDoacaoValor([]);
    setDataDoacaoQuantidade([]);
    setDataPrimeirosDoadoresByPeriodo([]);
    setDataFormaDoacao([]);
    setDataDoacoesPFePJ([]);
    setDataPrevisaoDoacao([]);
    setDataStatusBoleto([]);
    setDataCadastroReativados([]);
    setDataPalitagens([]);
    setDataMalaMensalEnviada([]);
    setDataArrecadacoesDebito([]);
    setDataRemessaRetorono([]);
    setDataPrimeirosDoadoresByPeriodo([]);
  };

  const firstLoadDebitos = async () => {
    const formaContribuicaoID = await loadFormaContribuicao();
    const convenioID = await loadConvenios(formaContribuicaoID);
  };


  useEffect(() => {
    loadFormaContribuicao();
    loadTiposPerfil();
    firstLoadDebitos();
    loadOrigensEspecificas();
    loadCorrespondencia();
    loadTodosConvenios();
    loadOrigemDoacao();
  }, []);


    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <Grid container alignItems="center" item lg={9} xs={12}>
      <Grid item md={12} xs={12} lg={12}>
        <TitlePage style={{ margin: 5, padding: 5 }}>Consultas</TitlePage>
      </Grid>
      {/* <Grid
        item
        md={2}
        xs={12}
        lg={2}
        style={{
          alignSelf: 'flex-end',
          paddingLeft: 10,
          paddingRight: 10,
          paddingTop: 10,
        }}
      >
        <Tag color={'default'}>Consulta</Tag>
      </Grid>*/}
      <Grid
        item
        md={3}
        lg={3}
        xs={12}
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          alignSelf: 'flex-end',
          paddingTop: 10,
        }}
      >
        <Button
          color="secondary"
          fullWidth
          style={{
            height: 46,
            width: 350
          }}
          onClick={handleOpen}
        >
          <TuneOutlined style={{ marginRight: 10 }} /> Selecionar consultas
        </Button>
      </Grid>

        <Grid item lg={2}
          style={{
            paddingRight: 10,
            paddingLeft: 10,
            alignSelf: 'flex-end',
            paddingTop: 10,
          }}
        >
          <Button
            color="primary"
            style={{
              height: 46,
              width: 200
            }}
            onClick={() => {
              window.open(dataLinkArquivo, '_blank');
              //window.location.reload();
            }}
            disabled={checkedGerarCSV === false || loadingButtonBuscar === false || dataLinkArquivo === null}
          >
            Gerar CSV
          </Button>
        </Grid>


      <Filtros abrir={filtros} openMenu={handleOpen}>
        <Grid
          item
          md={12}
          lg={12}
          xs={12}
          style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
        >
          <Form className="teste2 " layout={'vertical'}>
            <Form.Item
              label={<label style={{ color: '#fff' }}>Tipo consulta</label>}
            >
              <SelectAntd
                style={{ width: '100%' }}
                //placeholder="Tipo de Filtro"
                onChange={(e: string) => {
                  if (e === 'Aniversariantes' || e === 'Arrecadações débito por período' || e === 'Cadastros ativos por período' || e === 'Cadastros inativos sem forma de contribuição' || e === 'Cadastros por cidade'
                  || e === 'Cadastros por faixa etária e gênero' || e === 'Cadastros por operador por período' || e === 'Cadastros reativados por período'
                  || e === 'Doadores com e-mail' || e === 'Doações por faixa de valor'|| e === 'Doações por período' || e === 'Doações por quantidade de doação' || e === 'Envio de remessa e retorno'
                  || e === 'Mala mensal - quantitativo de doações' || e === 'Ocorrência de doação' || e === 'Origem doações'  || e === 'Palitagens por período'
                  || e === 'Previsão de doações' || e === 'Cards por período' || e === 'Status boleto por período'  || e === 'Ticket médio PF e PJ'
                  || e === 'Ticket médio por forma de doação' || e === 'Primeira doação no mês por período' ) {
                    setTipoFiltroSelected(e);
                    onChangeClearButton();
                  }
                }}
              >
                {tiposFiltros.map((option, index) => (
                  <SelectAntd.Option key={option} value={option}>
                    {option}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>
            </Form.Item>

            <InvisibleContent visible={tipoFiltroSelected === 'Doadores com e-mail'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
                  <Grid container >
                    <Grid item justify="space-around" lg={12} sm={12}  xs={12}>
                    <Form.Item name="gerar_arquivo" style={{ marginTop: -10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            marginLeft: 0,
                            width: 180,
                            height: 33,
                            paddingBottom: 20,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaDoacaoEmail(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
              </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por cidade'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>Tipo de Perfil</label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo de Perfil"
                    onChange={(id: number) => {
                      setIdTipoPerfil(id);
                    }}
                  >
                    {tiposPerfil.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}

                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12} style={{ flexDirection: 'row', marginBottom: 20}}>
                <Form
                  form={formRef}
                  size="middle"
                  layout="vertical"
                  scrollToFirstError={true}
                  labelAlign="left"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                >
                  <Grid container>
                    <Grid item md={3} lg={3} xs={12}>
                      <Form.Item
                        label={<label style={{ color: '#fff' }}>Estado</label>}
                      >
                        <SelectAntd
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Estado"
                          onChange={(estadoSelected: string) => {
                            setEstado(estadoSelected);
                          }}
                        >
                          {UFs.UF.map((option, index) => (
                            <SelectAntd.Option
                              key={option.label}
                              value={option.label}
                            >
                              {option.label}
                            </SelectAntd.Option>
                          ))}

                        </SelectAntd>
                      </Form.Item>
                    </Grid>


                    <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 21 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            marginLeft: 10,
                            width: 180,
                            height: 33,
                            paddingBottom: 20,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>


                </Form>
              </Grid>





              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaEstado(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 15 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros ativos por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                      Correspondência
                    </label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo de correspondência"
                    onChange={(id: number) => {
                      setIdCorrespondencia(id);
                    }}
                  >
                    {correspondencia.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}

                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                      Forma de Contribuição
                    </label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Forma de Contribuição"
                    onChange={(id: number) => {
                      setIdFormaContribuicao(id);
                    }}
                  >
                    {formasContribuicao.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}

                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>Tipo de Perfil</label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo de Perfil"
                    onChange={(id: number) => {
                      setIdTipoPerfil(id);
                    }}
                  >
                    {tiposPerfil.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}

                  </SelectAntd>
                </Form.Item>
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaPessoa(1, itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}

                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>

              </InvisibleContent>


            <InvisibleContent visible={tipoFiltroSelected === 'Aniversariantes'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de nascimento: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>



              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaAniversariantes(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46, paddingTop: 5}}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por faixa etária e gênero'}>
              <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <DatePicker
                    cleanable={false}
                    notUseDefaultValue={true}
                    title="Período de cadastro: "
                    color={'default'}
                    setValor={onChangeDates}
                    placement={'bottomEnd'}
                  />
                </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaFaixaEtaria(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
              </InvisibleContent>


            <InvisibleContent visible={tipoFiltroSelected === 'Ocorrência de doação'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de retorno: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>
              <Grid item lg={12} xs={12} md={12} style={{paddingLeft: 3}}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                      Forma de Contribuição
                    </label>
                  }
                >

                  {formasContribuicao.length > 0 &&
                    formasContribuicao.map(forma => {
                      if (
                        forma.descricao.toLowerCase().includes(DESCRICAO_DEBITO) ||
                        forma.descricao.toLowerCase().includes(DESCRICAO_BOLETO)
                      ) {
                        return (

                          <ButtonComponent
                    key={forma.id}
                    style={{ margin: 5 }}
                    color={
                      formaContribuicaoSelected != forma.id
                        ? 'default'
                        : 'primary'
                    }
                    disabledColor="#ccc"
                    onClick={() => {
                      setFormaContruibuicaoSelected(Number(forma.id));
                      loadConvenios(Number(forma.id));
                      setIdFormaContribuicao2(forma.id);
                    }}
                  >
                    {forma.descricao.toUpperCase()}
                  </ButtonComponent>


                      );
                    }
                  })}
                </Form.Item>
              </Grid>

            <Grid
              item md={12} lg={12} xs={12} >
              <Form.Item name="convenio"
                label={
                  <label style={{ color: '#fff' }}>
                    Convênio
                  </label>
                }
              >

                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Convênio"
                  onChange={(id: number, e: any) => {
                    setIdConvenio(id);
                  }}
                >
                  {convenios.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao + ' - ' + option.numero_convenio}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                  </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaOcorrencia(1, itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}

                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            {/*<InvisibleContent visible={tipoFiltroSelected === 'Perfil: Quantidade de ativos'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid item md={12} lg={12} xs={12}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>Tipo de Perfil</label>
                  }
                >
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo de Perfil"
                    onChange={(id: number) => {
                      setIdTipoPerfil(id);
                    }}
                  >
                    {tiposPerfil.map((option, index) => (
                      <SelectAntd.Option key={option.id} value={option.id}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}

                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaPessoa(1, itemsPerPage);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
                </InvisibleContent>*/}

            <InvisibleContent visible={tipoFiltroSelected === 'Origem doações'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de doação: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                   onChangeConsultaOrigemDoacao(1, itemsPerPage);
                   setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Doações por faixa de valor'}>
              <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <DatePicker
                    cleanable={false}
                    notUseDefaultValue={true}
                    title="Período de doação: "
                    color={'default'}
                    setValor={onChangeDates}
                    placement={'bottomEnd'}
                  />
                </Grid>

                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaDoacaoValor(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Doações por período'}>
              <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <DatePicker
                    cleanable={false}
                    notUseDefaultValue={true}
                    title="Período de doação: "
                    color={'default'}
                    setValor={onChangeDates}
                    placement={'bottomEnd'}
                  />
                </Grid>

                <Grid item md={12} lg={12} xs={12}>
                  <Form.Item name="origem_doacao">
                    <SelectAntd
                      style={{ width: '100%' }}
                      placeholder="Origem Doação"
                      onChange={(value: number, e: any) => {
                        setIdOrigemDoacao(value);
                      }}
                    >
                      <SelectAntd.Option value={-1}>TODOS</SelectAntd.Option>
                      {origemDoacao.map((option) => (
                        <SelectAntd.Option key={option.id} value={option.id}>
                          {option.descricao}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Form.Item>
                </Grid>

                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaDoacoesByPeriodo(1, itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Doações por quantidade de doação'}>
              <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ paddingTop: 10, paddingBottom: 10 }}
                >
                  <DatePicker
                    cleanable={false}
                    notUseDefaultValue={true}
                    title="Período de doação: "
                    color={'default'}
                    setValor={onChangeDates}
                    placement={'bottomEnd'}
                  />
                </Grid>

                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaDoacaoQuantidade(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cards por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro doação: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaPrimeirosDoadores(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>


            <InvisibleContent visible={tipoFiltroSelected === 'Ticket médio por forma de doação'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaFormaDoacao(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Ticket médio PF e PJ'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaDoacaoPFePJ(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Previsão de doações'}>
              {/*<Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
                </Grid>*/}

                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaPrevisaoDoacao(1,  itemsPerPage, checkedGerarCSV);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Status boleto por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de emissão: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaStatusBoleto(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros reativados por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de reativado: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaCadastrosReativados(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Palitagens por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de palitagem: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaListaPalitagens(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Mala mensal - quantitativo de doações'}>
            <Grid item lg={12} xs={12} md={12} style={{paddingLeft: 3}}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                     Ano
                    </label>
                  }
                >

                  {anoMalaMensal.length > 0 &&
                    anoMalaMensal.map(forma => {
                      if (
                        forma.id ||
                        forma.id
                      ) {
                        return (
                          <ButtonComponent
                            key={forma.id}
                            style={{ margin: 5 }}
                            color={
                              anoSelected != forma.id
                              ? 'default'
                              : 'primary'
                            }
                            disabledColor="#ccc"
                            onClick={() => {
                              setAnoSelected(Number(forma.id));
                           }}
                          >
                            {forma.id}
                          </ButtonComponent>
                        );
                      }
                  })}
                </Form.Item>
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <Form.Item
                  label={<label style={{ color: '#fff' }}>Mês</label>}
                >
                  <SelectAntd
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Estado"
                      onChange={(descricao: string) => {
                        setMesEstrategiaMensal(descricao);
                      }}
                  >
                    {mesMalaMensal.map((option, index) => (
                      <SelectAntd.Option
                        key={option.descricao}
                        value={option.descricao}
                      >
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                <Grid container >
                <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                </Grid>
              </Grid>

              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaMalaMensalEnviada(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Arrecadações débito por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de vencimento: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>

              <Grid
              item md={12} lg={12} xs={12}
            >
              <Form.Item name="convenio">
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Banco"
                  onChange={(id: number, e: any) => {
                    setIdConvenio(id);
                  }}
                >
                  {conveniosTodos.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao + ' - ' + option.numero_convenio}
                    </SelectAntd.Option>
                  ))}
                   <SelectAntd.Option value={0}>TODOS</SelectAntd.Option>
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>


              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaArrecadacoesDebito(1,  itemsPerPage);
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46 }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>


            <InvisibleContent visible={tipoFiltroSelected === 'Envio de remessa e retorno'}>

              <Grid item lg={12} xs={12} md={12} style={{paddingLeft: 3}}>
                <Form.Item
                  label={
                    <label style={{ color: '#fff' }}>
                      Forma de Contribuição
                    </label>
                  }
                >

                  {formasContribuicao.length > 0 &&
                    formasContribuicao.map(forma => {
                      if (
                        //forma.descricao.toLowerCase().includes(DESCRICAO_DEBITO) ||
                        forma.descricao.toLowerCase().includes(DESCRICAO_BOLETO2)
                      ) {
                        return (

                        <SelectAntd
                          key={forma.id}
                          style={{ width: '99%' }}
                          placeholder="Forma de contribuição"
                          onChange={(id: number, e: any) => {
                            setFormaContruibuicaoSelected(Number(forma.id));
                            loadConvenios(Number(forma.id));
                          }}
                        >
                          <SelectAntd.Option value={forma.id}>
                            {forma.descricao.toUpperCase()}
                          </SelectAntd.Option>
                        </SelectAntd>
                      );
                    }
                  })}
                </Form.Item>
              </Grid>

            <Grid
              item md={12} lg={12} xs={12} >
              <Form.Item name="convenio"
                label={
                  <label style={{ color: '#fff' }}>
                    Convênio
                  </label>
                }
              >

                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Convênio"
                  onChange={(id: number, e: any) => {
                    setIdConvenio(id);
                  }}
                >
                  {convenios.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao + ' - ' + option.numero_convenio}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                  </Grid>

            <Grid item lg={12} xs={12} md={12} style={{paddingTop: 10}}>
              <Button
                color="primary"
                onClick={() => {
                  onChangeConsultaRemessaRetorno(1,  itemsPerPage);
                  setLoadingButtonBuscar(true);
                }}
                fullWidth
                style={{ height: 46 }}
              >
                <SearchOutlined style={{ marginRight: 10 }} /> Buscar
              </Button>
            </Grid>
          </InvisibleContent>

          <InvisibleContent visible={tipoFiltroSelected === 'Primeira doação no mês por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>



              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaPrimeiraDoacaoPessoaMes(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46, paddingTop: 5}}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>

            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por operador por período'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10, marginBottom: 20}}>
                  <Grid container >
                  <Grid item lg={6} sm={12} xs={12}>
                      <Form.Item name="gerar_arquivo" style={{ marginTop: 10 }}>
                        <Checkbox
                          onChange={onChange}
                          style={{
                            backgroundColor: '#0d6ab1',
                            width: 180,
                            height: 32,
                            paddingBottom: 15,
                            borderRadius: 2,
                            justifyItems: 'center',
                            alignItems: 'center',

                          }}
                          value="gerar_arquivo"
                        >
                          <p style={{ color: '#fff', margin: 0, fontSize: 12, display: 'flex', marginTop: 13}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>



              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaCadastroOperador(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46, paddingTop: 5}}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>


            <InvisibleContent visible={tipoFiltroSelected === 'Cadastros inativos sem forma de contribuição'}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{ paddingTop: 10, paddingBottom: 10 }}
              >
                <DatePicker
                  cleanable={false}
                  notUseDefaultValue={true}
                  title="Período de cadastro: "
                  color={'default'}
                  setValor={onChangeDates}
                  placement={'bottomEnd'}
                />
              </Grid>



                <Grid container justify="space-around" lg={12} sm={12}  xs={12} style={{paddingTop: 10}}>
                  <Grid container >
                    <Grid item justify="space-around" lg={12} sm={12}  xs={12}>
                      <Form.Item name="gerar_arquivo" >
                        <Checkbox
                          onChange={onChange}
                          style={{backgroundColor: '#0d6ab1', width: 210, height: 40, paddingTop: 5, paddingBottom: 30}} value="gerar_arquivo">
                          <p style={{color: '#fff', paddingLeft: 5,fontSize: 12,}}>Gerar CSV da consulta</p>
                        </Checkbox>
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Grid>



              <Grid item lg={12} xs={12} md={12}>
                <Button
                  color="primary"
                  onClick={() => {
                    onChangeConsultaInativosSemContrib(1, itemsPerPage );
                    setLoadingButtonBuscar(true);
                  }}
                  fullWidth
                  style={{ height: 46, paddingTop: 5}}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </Button>
              </Grid>
            </InvisibleContent>



          </Form>
        </Grid>
      </Filtros>

           {/* ---------------- TABELAS --------------------- */}

      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por cidade'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['cidade', 'quantidade_cadastros_ativos']}
              FieldsBold={['cidade']}
              loading={loading}
              columns={[columnsLabelEstado]}
              data={dataEstado}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros ativos por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nome', 'tipo_perfil', 'nome_fantasia', 'origem_cadasro', 'tipo_correspondencia', 'forma_contribuicao' ]}
              FieldsBold={['tipo_perfil']}
              loading={loading}
              columns={[columnsLabelPessoa]}
              data={dataPessoa}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaPessoa) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      {/*<InvisibleContent visible={tipoFiltroSelected === 'Perfil: Quantidade de ativos'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nome',  'tipo_perfil']}
              FieldsBold={['nome']}
              loading={loading}
              columns={[columnsLabelPessoa]}
              data={dataPessoa}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
            />
          </Grid>
        </Grid>
                </InvisibleContent>*/}


      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por faixa etária e gênero'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3']}
              FieldsBold={['item1']}
              loading={loading}
              columns={[columnsLabelFaixaEtaria]}
              data={dataFaixaEtaria}
              //itemsPerPage={itemsPerPage}
              //totalPages={totalPages}
              //pageCurrent={page}
             // fetchData={setPagination}
              totalItens={totalItens}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Ocorrência de doação'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nome_pessoa', 'status_ocorrencia', 'convenio', 'data_vencimento']}
              FieldsBold={['id_pessoa', 'status_ocorrencia']}
              loading={loading}
              columns={[columnsLabelOcorrencia]}
              data={dataOcorrencia}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaOcorrencia) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>



      <InvisibleContent visible={tipoFiltroSelected ===  'Aniversariantes'}>

        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nome', 'tipo_perfil', 'data_aniversario']}
              FieldsBold={['tipo_perfil']}
              loading={loading}
              columns={[columnsLabelAniversariante]}
              data={dataAniversariante}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaAniversariante) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Doadores com e-mail'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nome', 'email', 'valor_doado', 'vezes_doadas']}
              FieldsBold={['id_pessoa', 'email']}
              loading={loading}
              columns={[columnsLabelDoacaoEmail]}
              data={dataDoacaoEmail}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaDoacaoEmail) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Origem doações'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3']}
              FieldsBold={['item1']}
              loading={loading}
              columns={[columnsLabelOrigemDoacao]}
              data={dataOrigemDoacao}
              //itemsPerPage={}
              //totalPages={}
              //pageCurrent={}
              //fetchData={}
              //totalItens={}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Doações por faixa de valor'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3', 'item4']}
              FieldsBold={['item1', 'item4']}
              loading={loading}
              columns={[columnsLabelDoacaoValor]}
              data={dataDoacaoValor}
              //itemsPerPage={}
              //totalPages={}
              //pageCurrent={}
              //fetchData={}
              //totalItens={}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Doações por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
          <TableActions
              keys={['dataCadastro', 'dataCredito', 'idDoacao', 'idPessoa', 'nomePessoa', 'tarifa', 'valorDoacao', 'origemDoacao']}
              loading={loading}
              columns={[columnsLabelDoacoesByPeriodo]}
              data={dataOrigemDoacaoByPeriodo}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Doações por quantidade de doação'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3', 'item4']}
              FieldsBold={['item1', 'item4']}
              loading={loading}
              columns={[columnsLabelDoacaoQuantidade]}
              data={dataDoacaoQuantidade}
              //itemsPerPage={}
              //totalPages={}
              //pageCurrent={}
              //fetchData={}
              //totalItens={}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Cards por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 5 }}>
            <TableActions
              keys={['data_cadastro', 'id_pessoa', 'nome', 'documento', 'tipo_perfil', 'forma_contribuicao', 'data_doacao', 'valor_doacao']}
              FieldsBold={['id_pessoa', 'valor_doacao']}
              loading={loading}
              columns={[columnsLabelPrimeirosDoadores]}
              data={dataPrimeirosDoadores}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaPrimeirosDoadoresByPeriodo) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Ticket médio por forma de doação'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3', 'item4']}
              FieldsBold={['item1', 'item3']}
              loading={loading}
              columns={[columnsLabelFormaDoacao]}
              data={dataFormaDoacao}
              //itemsPerPage={itemsPerPage}
              //totalPages={totalPages}
              //pageCurrent={page}
              //fetchData={setPagination}
              //totalItens={totalItens}
              //Actions={[]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Ticket médio PF e PJ'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3',]}
              FieldsBold={['item1', 'item3']}
              loading={loading}
              columns={[columnsLabelDoacoesPFePJ]}
              data={dataDoacoesPFePJ}
              //itemsPerPage={itemsPerPage}
              //totalPages={totalPages}
              //pageCurrent={page}
              //fetchData={setPagination}
              //totalItens={totalItens}
              //Actions={[]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected ===  'Previsão de doações'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['item1', 'item2', 'item3',]}
              FieldsBold={['item1', 'item3']}
              loading={loading}
              columns={[columnsLabelDoacoesPrevisaoCredito]}
              data={dataPrevisaoDoacao}
              //itemsPerPage={itemsPerPage}
              //totalPages={totalPages}
              //pageCurrent={page}
              //fetchData={setPagination}
              //totalItens={totalItens}
              //Actions={[]}
            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Status boleto por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 5 }}>
            <TableActions
              keys={['usuario', 'numeroEmisssao', 'dataEmissaoBoleto', 'dataVencimentoBoleto', 'statusBoleto', 'idCrm', 'nomePessoa', 'estrategia']}
              FieldsBold={['statusBoleto']}
              loading={loading}
              columns={[columnsLabelDoacoesBoletoStatus]}
              data={dataStatusBoleto}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaBoletoStatus) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.idCrm}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros reativados por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 3 }}>
            <TableActions
              keys={['idPessoa', 'nomePessoa', 'origemCadastro', 'motivoCadastro', 'formaContribuicao', 'tipoCorrespondencia',]}
              FieldsBold={['idPessoa']}
              loading={loading}
              columns={[columnsLabelCadastroReativados]}
              data={dataCadastroReativados}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaCadastroReativados) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.idPessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Palitagens por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['nomeUsuario', 'idCadastro', 'nomeCadastro', 'diaLigacao', 'horarioInicioLigacao', 'horarioFimLigacao', 'observacao', 'statusLigacao', 'estrategiaLigacao']}
              FieldsBold={['idCadastro']}
              loading={loading}
              columns={[columnsLabelListaPalitagens]}
              data={dataPalitagens}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Mala mensal - quantitativo de doações'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableWithTotalItens
              keys={['tipoEnvio', 'qntDoacoes', 'valorTotalDoacao', 'mediaDoacao']}
              FieldsBold={['tipoEnvio', 'valorTotalDoacao']}
              loading={loading}
              columns={[columnsLabelMalaMensalEnviada]}
              data={dataMalaMensalEnviada}
              //itemsPerPage={itemsPerPage}
              //totalPages={totalPages}
              //pageCurrent={page}
              //fetchData={setPagination}
              //totalItens={totalItens}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Arrecadações débito por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id', 'nome', 'vencimento', 'valor', 'descricao', 'banco']}
              FieldsBold={['nome', 'valor']}
              loading={loading}
              columns={[columnsLabelArrecadacoesDebito]}
              data={dataArrecadacoesDebito}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaArrecadacoesDebito) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Envio de remessa e retorno'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['convenio', 'banco', 'arquivo', 'boletosAguardandoRetorno', 'boletosAguardandoEnvio', 'boletosRejeitados', 'boletosPagos', 'boletosRegistrados', 'boletosVencidos', 'aguardandoRetorno']}
              FieldsBold={['banco']}
              loading={loading}
              columns={[columnsLabelRemessaRetorno]}
              data={dataRemessaRetorno}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Primeira doação no mês por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['nome', 'nome_fantasia', 'documento', 'valor_primeira_doacao', 'data_primeira_doacao']}
              FieldsBold={['nome_fantasia', 'valor_primeira_doacao']}
              loading={loading}
              columns={[columnsLabelPrimeiraDoacaoPessoaMes]}
              data={dataPrimeiraDoacaoPessoaMes}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros por operador por período'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['codigoUsuario', 'nomeUsuario', 'data_cadastro', 'idCadastro', 'nomeCadastro', 'apelido', 'documento', 'tipo_perfil', 'origem_cadastro', 'forma_contribuicao', 'recorrente', 'tipo_correspondencia', 'endereco', 'bairro', 'complemento', 'cidade', 'estado']}
              FieldsBold={['nome_fantasia', 'valor_primeira_doacao']}
              loading={loading}
              columns={[columnsLabelCdadastroOperador]}
              data={dataCadastroOperador}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}

            />
          </Grid>
        </Grid>
      </InvisibleContent>

      <InvisibleContent visible={tipoFiltroSelected === 'Cadastros inativos sem forma de contribuição'}>
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TableActions
              keys={['id_pessoa', 'nomeCompleto', 'FormaContribuicao', 'datadecadastro']}
              FieldsBold={['nomeCompleto']}
              loading={loading}
              columns={[columnsLabelInativosSemFormaContrib]}
              data={inativosSemContrib}
              itemsPerPage={itemsPerPage}
              totalPages={totalPages}
              pageCurrent={page}
              fetchData={setPagination}
              totalItens={totalItens}
              Actions={[
                {
                  Button: (row: IConsultaInativosSemContrib) => {
                    return (
                      <IconButton
                        onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                      >
                        <Visibility />
                      </IconButton>
                    );
                  },
                },
              ]}


            />
          </Grid>
        </Grid>
      </InvisibleContent>


    </Grid>
  );
};

export default Consultas;
