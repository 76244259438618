import api from '@http/api';

export interface IResumoDoacao {
  total_doacoes: number;
  primeira_doacao: string;
  ultima_doacao: string;
  media_doacoes: string;
}

interface IResponseResumoDoacao {
  error: boolean;
  response?: IResumoDoacao;
}

interface IResponseResumoPerfil {
  error: boolean;
  response?: IPessoa;
}

interface IRequestResumo {
  idPerfil: number;
}

interface IRequestResumoPessoa {
  idPessoa: number;
}

interface IRequestTable {
  idPerfil: number;
  // ultimaDoacaoId: number;
  qntItemsPagina: number;
  pagina: number;
}

export interface IModelo {
  id: string;
  data_doacao: string;
  origem_doacao_descricao: string;
  campanha_descricao: string;
  valor_doacao: string;
  observacao: string;
}

interface IResponse {
  error: boolean;
  response?: IModelo[];
}

interface IPessoa {
  nome: string;
  documento: string;
}

class Services {
  public async getResumoDoacao({
    idPerfil
  }: IRequestResumo): Promise<IResponseResumoDoacao> {
    return await api
      .get(`/api/Doacao/GetResumoDoacoes?id_perfil=${idPerfil}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoa({
  idPessoa, 
}: IRequestResumoPessoa): Promise<IResponseResumoPerfil> {
  return await api
    .get(`/api/Pessoa/GetPessoaById/${idPessoa}`) 
    .then(response => {
      const pessoa = response.data.pessoa;  
      return {
        error: false,
        response: {
          nome: pessoa.nome,
          documento: pessoa.documento
        }
      };
    })
    .catch(() => {
      return {
        error: true,
      };
    });
}
}

export default Services;
