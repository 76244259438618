import api from '@http/api';

// export interface IOrigemEspecifica {
//   id: string;
//   descricao: string;
//   data_cadastro: string;
//   status_ativo: boolean;
// }

// export interface IMotivo {
//   id: string;
//   descricao: string;
//   data_cadastro: string;
//   status_ativo: boolean;
// }

export interface IData {
  id: string;
  descricao: string;
}

export interface IResponse {
  error: boolean;
  response: IData[] | [];
}

export interface ICreate {
  origem_macro_id?: string;
  origem_especifica_id?: string;
  descricao: string;
  status_ativo: number;
}

export interface IUpdate {
  origem_macro_id?: string;
  id: string;
  descricao: string;
  status_ativo: number;
}

export interface IDelete {
  id: string;
}

class Services {
  public async loadTiposPerfil(): Promise<IResponse> {
    return await api
      .get<IData[]>('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadOrigemMacro(): Promise<IResponse> {
    return await api
      .get<IData[]>('/api/OrigemMacro/GetOrigensMacro')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadOrigemEspecifica(): Promise<IResponse> {
    return await api
      .get<IData[]>('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadMotivos(): Promise<IResponse> {
    return await api
      .get<IData[]>('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadTipoPerfil(): Promise<IResponse> {
    return await api
      .get<IData[]>('/api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async createOrigemMacro({ descricao, status_ativo }: ICreate) {
    return await api
      .post('/api/OrigemMacro/Create', {
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createOrigemEspecifica({
    descricao,
    status_ativo,
    origem_macro_id,
  }: ICreate) {
    return await api
      .post('/api/OrigemEspecifica/Create', {
        origem_macro_id,
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createMotivo({ descricao, origem_especifica_id, status_ativo }: ICreate) {
    return await api
      .post('/api/MotivoCadastro/Create', {
        descricao,
        origem_especifica_id,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createTipoPerfil({ descricao, status_ativo }: ICreate) {
    return await api
      .post('/api/TipoPerfil/Create', {
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async updateOrigemMacro({ id, descricao, status_ativo }: IUpdate) {
    return await api
      .put('/api/OrigemMacro/UpdateById', {
        id,
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async updateOrigemEspecifica({
    id,
    descricao,
    status_ativo,
    origem_macro_id,
  }: IUpdate) {
    return await api
      .put('/api/OrigemEspecifica/UpdateById', {
        id,
        descricao,
        origem_macro_id,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async updateMotivo({ id, descricao, status_ativo }: IUpdate) {
    return await api
      .put('/api/MotivoCadastro/UpdateById', {
        id,
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async updateTipoPerfil({ id, descricao, status_ativo }: IUpdate) {
    return await api
      .put('/api/TipoPerfil/UpdateById', {
        id,
        descricao,
        status_ativo,
      })
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async deleteTipoPerfil({ id }: IDelete) {
    return await api
      .delete(`/api/TipoPerfil/${id}`)
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async deleteOrigemMacro({ id }: IDelete) {
    return await api
      .delete(`/api/OrigemMacro/${id}`)
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async deleteOrigemEspecifica({ id }: IDelete) {
    return await api
      .delete(`/api/OrigemEspecifica/${id}`)
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async deleteMotivo({ id }: IDelete) {
    return await api
      .delete(`/api/MotivoCadastro/${id}`)
      .then(() => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default Services;
