import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin, Radio } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { SubTitleBold } from '@src/@styles/styles.global';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { AddOutlined, Visibility } from '@material-ui/icons';

import { IPerfil } from '@interfaces/UserComplete';
import useDebounce from '@hooks/useDebounce';
import Services,
{IMotivoDevolucao,
IRequestCreateCorrespDevolvida} from './services';
import InvisibleContent from '@components/Shared/InvisibleContent';


interface IProps {
  finishCreate?: Function;
}

const optionsFilter = ['ID', 'CPF/CNPJ'];

const CorrespondenciaDevolvidaComponent:React.FC<IProps> = ({

  finishCreate,

}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const [loadingCreate, setLoadingCreate] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [statePessoa, setStatePessoa] = useState({
    Nome: '',
    id: '',
  });
  const [viewConfirmation, setViewConfirmation] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );
  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const [perfis, setPerfis] = useState<IPerfil[]>([]);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);

  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [enableSearchForm, setEnableSearchForm] = useState(false);

  const [motivoDevolucao, setMotivoDevolucao] = useState<IMotivoDevolucao[]>([]);

  const [loadingCreateCorrespDevolvida, setLoadingCreateCorrespDevolvida] =
  useState(false);

  const handleMenuItemClick = (_: any, option: any) => {
    console.log(option);
    setTipoPerfilSelected(option.key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);
    //setSelectedFilterIndicador(value);
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };

  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

  const { error, response } = await service.getPessoaByDocumento(documento);

  if (!error && response) {
    setPerfis(response.pessoa.perfis);
    if (response.pessoa.perfis.length <= 0) {
      setRespostaErroBuscaPerfil(
        'Não existe perfil cadastrado para este CPF/CNPJ',
      );
    }
    if (!error && response) {
      setStatePessoa({
        Nome: response.pessoa.nome,
        id: response.pessoa.id,
      });
      setPerfis(response.pessoa.perfis);
      setViewConfirmation(true);
    }
  } else {
    setViewConfirmation(false);
    setRespostaErroBuscaPerfil(
      'Não existe uma pessoa cadastrada para este CPF/CNPJ',

    );
  }
  setLoadingTipoPerfil(false);
};

const getPessoaByID = async (id: number) => {
  setRespostaErroBuscaPerfil('');
  setLoadingTipoPerfil(true);
  const service = new Services();

  const { error, response } = await service.getPessoaByID(id);

  if (!error && response) {
    setPerfis(response.pessoa.perfis);
    if (response.pessoa.perfis.length <= 0) {
      setRespostaErroBuscaPerfil('Não existe perfil cadastrado para este ID');
    }
    if (!error && response) {
      setStatePessoa({
        Nome: response.pessoa.nome,
        id: response.pessoa.id,
      });
      setPerfis(response.pessoa.perfis);
      setViewConfirmation(true);
    }
  } else {
    setRespostaErroBuscaPerfil(
      'Não existe uma pessoa cadastrada para este ID',
    );
    setViewConfirmation(false);
  }
  setLoadingTipoPerfil(false);
};

const debounceOnChange = (value: string) => {
  formRef.setFieldsValue({
    tipo_perfil: undefined,
  });
  setPerfis([]);
  if (selectedFilter === 'CPF/CNPJ') {
    debounceGetPessoaByDocumento(value);
  } else if (selectedFilter === 'ID') {
    debounceGetPessoaByID(parseInt(value));
  }
};

const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
const debounceGetPessoaByID = useDebounce(getPessoaByID, 1000);

const loadMotivosDevolucao = async () => {
  const serviceCartaDevolvidaCreate = new Services();

  const motivoResponse = await serviceCartaDevolvidaCreate.GetMotivoDevolucao();

  if (!motivoResponse.error && motivoResponse.response) {
    setMotivoDevolucao(motivoResponse.response);
  }
};

async function onSubmit(values: any) {
  setLoadingCreate(true);

  console.log(values);

  const createCorrespDevolvida: IRequestCreateCorrespDevolvida = {

    observacao: values.observacao,
    data_envio: values.data_envio,
    data_retorno: values.data_retorno,
    mala_mensal: values.mala_mensal,
    id_perfil: values.id_perfil,
    id_motivo_devolucao: values.id_motivo_devolucao
  };

  const service = new Services();
  const { error, response } = await service.CreateCorrespDevolvida(
    createCorrespDevolvida,
  );

  setLoadingCreate(false);

  if (!error) {
    enqueueSnackbar(`Cadastro adicionado com sucesso!`, {
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
    setLoadingCreateCorrespDevolvida(false);
    if (finishCreate) {
      finishCreate();
    }
  } else {
    enqueueSnackbar(`Ocorreu um erro`, {
      variant: 'error',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
    setLoadingCreateCorrespDevolvida(false);
  }

  window.location.reload();

}



useEffect(() => {
  loadMotivosDevolucao();
  window.document.title = 'CRM - Nova Correspondência Devolvida';
}, []);


 return (
  <Grid container>
    <Grid container item sm={12}>
      <Grid
        container item xs={12} md={12}
        justify="center" alignItems="center"
      >
        <Form
          form={formRef}
          scrollToFirstError={true}
          onFinish={onSubmit}
          requiredMark={false}
          style={{ width: '100%' }}
          layout="vertical"
        >
          <Grid item container justify="space-around" sm={12} xs={12} lg={12}>

            <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
              <Grid container>
                <Grid container item md={5} lg={5} xs={5} style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}>
                  <Grid item  md={5} lg={5} xs={12}
                    style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                  >
                    Filtros
                    <SelectAntd
                      style={{ width: '100%' }}
                      onChange={(e: string) => {
                        setValueInput('');
                        handleSelectFilter(e);
                        setViewConfirmation(false);
                        formRef.setFieldsValue({
                          cpf: undefined,
                        });
                        setEnableSearchForm(true);
                      }}
                    >
                      {optionsFilter.map((option, index) => (
                        <SelectAntd.Option key={option} value={option}>
                          {option}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Grid>

                  <Grid item md={6} lg={6} xs={12}
                    style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="cpf"
                      label="Buscar por"
                      rules={[
                        {
                          required: true,
                          message: 'Digite um documento',
                        },
                      ]}
                      style={{ paddingTop: 0 }}
                    >
                      <Input
                        disabled={!enableSearchForm}
                        minLength={14}
                        maxLength={18}
                        name={selectedFilter}
                        value={valueInput}
                        type={
                          selectedFilter === 'ID' || selectedFilter === 'ID AFIPE'
                          ? 'number'
                          : 'text'
                        }
                        onChange={(e: any) => {
                          setViewConfirmation(false);
                          if (selectedFilter === 'CPF/CNPJ') {
                            formRef.setFieldsValue({
                              cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                            });
                          }
                          if (
                            selectedFilter === 'CPF/CNPJ' &&
                            e.target.value.length >= 14
                          ) {
                            debounceOnChange(e.target.value);
                          } else if (selectedFilter !== 'CPF/CNPJ') {
                            debounceOnChange(e.target.value);
                          }
                        }}
                      />
                    </Form.Item>
                  </Grid>
                </Grid>

                {viewConfirmation && (
                  <Grid container item md={7} lg={7} xs={7} style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}>
                    <Grid item  md={6} lg={6} xs={12} style={{ marginTop: 10 }}>
                      <Form.Item  label="Nome">
                        <strong>
                          <Input
                            placeholder="Nome"
                            value= {statePessoa.Nome}
                          />
                        </strong>
                      </Form.Item>
                    </Grid>

                    <Grid
                    item md={4} lg={4} xs={12}
                    style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                  >
                    <Form.Item
                      name="id_perfil"
                      label="Tipo de perfil"
                      rules={[
                        {
                          required: true,
                          message: 'Selecione um perfil',
                        },
                      ]}
                      style={{ paddingTop: 0 }}
                    >
                      <SelectAntd
                        disabled={perfis.length <= 0}
                        placeholder="Tipo de perfil"
                      >
                        {perfis.map((item, index) => (
                          <SelectAntd.Option key={index} value={item.perfil_id}>
                            {item.tipo_perfil_descricao}
                          </SelectAntd.Option>
                        ))}
                      </SelectAntd>
                    </Form.Item>
                  </Grid>

                    <Grid item sm={2} xs={12} style={{  marginTop: 25 }}>
                      <Form.Item>
                        <IconButton
                          onClick={() => window.open(`/cadastro/${statePessoa.id}/`)}
                        >
                          <Visibility />
                        </IconButton>
                      </Form.Item>
                    </Grid>


                  </Grid>
                )}
                <div style={{color: "#FF0000"} }>
                {respostaErroBuscaPerfil}
                </div>

              </Grid>
            </Spin>
          </Grid>

          <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
            <Grid item  md={3} lg={3} xs={12} style={{ marginTop: 3 }}>
              <Form.Item name="data_envio" label="Data de envio"
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma data',
                  },
                ]}
              >
                <Input
                  type="date"
                  placeholder= 'Data de envio'

                />
              </Form.Item>
            </Grid>

            <Grid item  md={3} lg={3} xs={12} style={{ marginTop: 3 }}>
              <Form.Item name="data_retorno" label="Data de devolução"
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma data',
                  },
                ]}
              >
                <Input
                type="date"
                placeholder= 'Data de devolução'
                />
              </Form.Item>
            </Grid>

            <Grid item container justify="space-around"  md={3} lg={3} xs={12} style={{ marginTop: 3 }}>
              <Form.Item name="mala_mensal" label="Mala mensal"
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma opção',
                  },
                ]}
              >
                <Radio.Group>
                    <Radio value={true}>Sim</Radio>
                    <Radio value={false}>Não</Radio>
                  </Radio.Group>
              </Form.Item>
            </Grid>

            <Grid item  md={3} lg={3} xs={12} style={{ marginTop: 3 }}>
              <Form.Item name="id_motivo_devolucao" label="Motivo devolução"
                rules={[
                  {
                    required: true,
                    message: 'Selecione um motivo',
                  },
                ]}
              >
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Motivo devolução"
                >
                   {motivoDevolucao.map(item => (
                              <SelectAntd.Option key={item.id} value={item.id}>
                                {item.descricao}
                              </SelectAntd.Option>
                            ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

          </Grid>

          <Grid item container sm={12} xs={12} lg={12}>
            <Grid item  md={12} lg={12} xs={12} style={{ marginTop: 3 }}>
              <Form.Item name="observacao" label="Observação">
                <Input
                />
              </Form.Item>
            </Grid>
          </Grid>
          <InvisibleContent visible={loadingTipoPerfil === true || respostaErroBuscaPerfil !== ''}>
            <Grid container justify="center" alignItems="center">
              <Grid item sm={6} xs={6} style={{ paddingTop: 10 }}>
                <ButtonComponent
                  color="primary"
                  disabled

                  fullWidth
                  type="submit"
                >
                  <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                </ButtonComponent>
              </Grid>
            </Grid>
          </InvisibleContent>

          <InvisibleContent visible={loadingTipoPerfil === false && respostaErroBuscaPerfil === ''}>
            <Grid container justify="center" alignItems="center">
              <Grid item sm={6} xs={6} style={{ paddingTop: 10 }}>
                <ButtonComponent
                  color="primary"
                  disabled={loadingCreateCorrespDevolvida}
                  loading={loadingCreateCorrespDevolvida}
                  fullWidth
                  type="submit"
                >
                  <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                </ButtonComponent>
              </Grid>
            </Grid>
          </InvisibleContent>
        </Form>
      </Grid>
    </Grid>
</Grid>
  );
};

export default CorrespondenciaDevolvidaComponent;
