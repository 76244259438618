import React, { useState, useEffect } from 'react';
import { Grid, TextField, IconButton, Button } from '@material-ui/core';
import TableCollapsible from '../../Tables/TableColapseNoPaginationDoacao';
import api from '@http/api';
import { CurrencyFormat, FormatDate } from '@utils/formats';
import { getColors } from '@styles/colorsTheme';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone'
import InvisibleContent from '@components/Shared/InvisibleContent';
import Services, { IModelo } from './services';
import { useSnackbar } from 'notistack';

interface IProps {
  idPerfil: number;
  idPessoa: number;
}

const GetDoacoes: React.FC<IProps> = ({ idPerfil, idPessoa }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IModelo[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [resumoDoacaoMedia, setResumoDoacaoMedia] = useState('');
  const [resumoDoacaoUltimaData, setResumoDoacaoUltimaData] = useState('');
  const [resumoDoacaoPrimeiraData, setResumoDoacaoPrimeiraData] = useState('');
  const [resumoDoacaoTotal, setResumoDoacaoTotal] = useState<number>(0);
  const [resumoPessoaNome, setResumoPessoaNome] = useState<string>('');
  const [resumoPessoaDocumento, setResumoPessoaDocumento] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const [qntItemsPagina, setQntItemsPagina] = useState(5);
  const [pagina, setPagina] = useState(0);

  const columnsLabel = {
    data_doacao: 'Data',
    origem_doacao_descricao: 'Origem',
    campanha_descricao: 'Campanha',
    valor_doacao: 'Valor',
    observacao: 'Observacao',
  };

  // Função para carregar doações
  async function loadData(page: number) {
    setLoading(true);
    try {
      const response = await api.get(
        `/api/Doacao/FindDoacoesByPerfil?idPerfil=${idPerfil}&qntItemsPagina=${qntItemsPagina}&pagina=${page}`
      );

      const items = response.data.doacoes || [];
      if (items.length > 0) {

        const itemData: IModelo[] = items.map((item: IModelo) => ({
          data_doacao: FormatDate(item.data_doacao),
          valor_doacao: CurrencyFormat(parseFloat(item.valor_doacao)),
          origem_doacao_descricao: item.origem_doacao_descricao,
          campanha_descricao: item.campanha_descricao,
          observacao: item.observacao || '',
        }));

        setData(itemData);
      } else {
        setData([]); 
        enqueueSnackbar('Nenhuma doação encontrada para esta página.', {
          variant: 'info',
        });
      }
    } catch (error) {
      console.error('Erro ao carregar doações:', error);
      enqueueSnackbar('Ocorreu um erro ao carregar as doações: ' + (error instanceof Error ? error.message : 'Erro desconhecido'), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadData(pagina); 
  }, [pagina]); 



    // Carregar resumos de doações e perfil
    useEffect(() => {
      const loadResumoDoacoes = async () => {
        const services = new Services();
        const { error, response } = await services.getResumoDoacao({ idPerfil });
        if (!error && response) {
          setResumoDoacaoMedia(response.media_doacoes);
          setResumoDoacaoPrimeiraData(FormatDate(response.primeira_doacao));
          setResumoDoacaoUltimaData(FormatDate(response.ultima_doacao));
          setResumoDoacaoTotal(response.total_doacoes);
        }
      };
  
      const loadResumoPerfil = async () => {
        const services = new Services();
        const { error, response } = await services.getPessoa({ idPessoa });
        if (!error && response) {
          setResumoPessoaNome(response.nome);
          setResumoPessoaDocumento(response.documento);
        }
      };
  
      loadResumoDoacoes();
      loadResumoPerfil();
    }, [idPerfil, idPessoa]);


    const formatDocument = (value: string) => {
      // Remove tudo que não for número
      value = value.replace(/\D/g, '');
  
      if (value.length <= 11) {
        // Máscara de CPF
        return value
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      } else {
        // Máscara de CNPJ
        return value
          .replace(/(\d{2})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1/$2')
          .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
      }
    };  



  const handleNextPage = () => {
    setPagina((prevPagina) => prevPagina + 1); 
  };

  const handlePrevPage = () => {
    if (pagina > 0) setPagina((prevPagina) => prevPagina - 1); 
  };

  const filteredData = data.filter((item) =>
    item.observacao.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>

<Grid
        container
        style={{
          paddingRight: 10,
          paddingLeft: 10,
          marginTop: 2,
          marginBottom: 0,
        }}
        justify="center"
        alignItems="center"
      >
          <Grid container justify="center" alignItems="center">
            <Grid item sm={6} xs={12}>
              <Grid container>
              <Grid item xs={12}>
              <p style={{fontSize: '14px', color: '#616161' }}>
                Nome: <strong style={{ fontWeight: 'bold', fontSize: '14px', color: '#616161', marginTop: '-1px' }}> {resumoPessoaNome ? resumoPessoaNome : '-- Nome não encontrado --'} </strong>
              </p>
              <p style={{fontSize: '14px', color: '#616161', marginTop: '-1px' }}>
                Documento: <strong style={{ fontWeight: 'bold', fontSize: '14px', color: '#616161', marginTop: '-1px' }}>{resumoPessoaDocumento ? formatDocument(resumoPessoaDocumento) : '-- Documento não encontrado --'}</strong> 
              </p>
            </Grid>
                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Média doação:{' '}
                      <strong>
                        {CurrencyFormat(
                          parseFloat(resumoDoacaoMedia),
                        )}{' '}
                      </strong>
                  </p>
                </Grid>

                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Total doação:{' '}
                      <strong>
                        {resumoDoacaoTotal}
                      </strong>
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={6} xs={12}>
              <Grid container>
                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Primeira doação em:{' '}
                      <strong>
                        {(
                          resumoDoacaoPrimeiraData
                          ) === '01/01/0001'
                          ? '--/--/----'
                          : (
                            resumoDoacaoPrimeiraData
                        )}
                      </strong>
                  </p>
                </Grid>

                <Grid item md={12}>
                  <p style={{ color: '#616161' }}>
                    Última doação em:{' '}
                      <strong>
                        {(
                          resumoDoacaoUltimaData
                          ) === '01/01/0001'
                          ? '--/--/----'
                          : (
                          resumoDoacaoUltimaData
                        )}
                      </strong>
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
      </Grid>

      <Grid container>
        <TextField
          label="Pesquisar"
          variant="outlined"
          margin="normal"
          style={{ width: '100%' }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Grid>

      <TableCollapsible
        loading={loading}
        columns={[columnsLabel]}
        data={filteredData}
        keys={['Data', 'valor', 'Origem', 'Campanha', 'Detalhes']}
        ItemBottom={
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 20 }}
            >
                <Button
                    onClick={handlePrevPage}
                    variant="contained"
                    color="primary" 
                    style={{ 
                        padding: '10px 20px',
                        borderRadius: '5px',
                    }}
                    startIcon={<ExpandLessTwoToneIcon />} 
                >
                    Página Anterior
                </Button>
                <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                    Página {pagina + 1}
                </span>
                <Button
                    onClick={handleNextPage}
                    variant="contained"
                    color="primary" 
                    style={{ 
                        padding: '10px 20px',
                        borderRadius: '5px',
                    }}
                    endIcon={<ExpandMoreTwoToneIcon />} 
                >
                    Próxima Página
                </Button>
            </Grid>
          }
        />
    </>
  );
};

export default GetDoacoes;
